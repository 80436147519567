import { Component, Input } from '@angular/core';
import { Layer } from '../../../../shared/model/dwg-asset.model';
import { DwgAssetWithForms } from '../shared/import-data-with-form.model';

@Component({
  selector: 'app-dwg-layers',
  templateUrl: './dwg-layers.component.html',
  styleUrls: ['./dwg-layers.component.scss']
})
export class DwgLayersComponent {

  @Input() dwgAssetWithForm?: DwgAssetWithForms;

  identifyLayers(index: string, layers: Layer) {
    return layers.name;
  }
}
