<p class="si-h1">{{ 'WIZARD.SELECT_BLOCKS'| translate}}</p>
<hr>
<form *ngIf="dwgAssetWithForm else loading" [formGroup]="dwgAssetWithForm.blocksWithForm.form">
  <si-form-container #formContainer [form]="dwgAssetWithForm.blocksWithForm.form" [enableValidationHelp]="false">
    <div class="container-fluid" si-form-container-content>
      <div class="row">
        <div class="col-6">
          <si-form-item [labelWidth]="0" [hideBorder]="true">
            <div class="form-check form-check-inline">
              <label for="blocks">{{ 'WIZARD.BLOCKS'| translate}}</label>
            </div>
          </si-form-item>
        </div>
        <div class="col-6">
          <si-form-item [labelWidth]="0" [hideBorder]="true">
            <div class="form-check form-check-inline">
              <label for="type">{{ 'WIZARD.TYPE'| translate}}</label>
            </div>
          </si-form-item>
        </div>

      </div>
      <div formArrayName="blocks" *ngFor="let block of blocksFormArray.controls; let i = index; trackBy:identifyBlocks">
        <div class="row">
          <div class="col-6">
            <si-form-item [hideBorder]="true" [labelWidth]="0">
              <div class="form-control-has-icon" [formGroupName]="i">
                <input type="checkbox" formControlName="selected" id="blockName" class="form-check-input">
                <label class="form-check-label" for="blockName"
                  translate>{{dwgAssetWithForm.blocksWithForm.list[i].name}}</label>
              </div>
            </si-form-item>
          </div>
          <div class="col-6">
            <si-form-item [labelWidth]="0">
              <div [formGroupName]="i" class="form-control-has-icon w-100">
                <select aria-label="select-block" formControlName="deviceType" class="form-select">
                  <option value="">{{ 'WIZARD.SELECT_DEVICE_TYPE'| translate}}</option>
                  <ng-container *ngIf="block.get('selected').value === true">
                    <option *ngFor="let deviceType of deviceTypes; trackBy:identifyDeviceType" [value]="deviceType.id">
                      {{deviceType.name}}
                    </option>
                  </ng-container>
                </select>
                <i class="invalid-icon element-cancel-filled"></i>
              </div>
            </si-form-item>
          </div>
        </div>
      </div>
    </div>
  </si-form-container>
</form>

<ng-template #notfound>
  <si-empty-state icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_MESSAGE4' | translate}}"
    content="{{'WIZARD.ERROR' | translate}}">
  </si-empty-state>
</ng-template>
<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
