import { Inject, Injectable, OnDestroy } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DOCUMENT, registerLocaleData } from '@angular/common';
// implementation example: import localeDe from '@angular/common/locales/de';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable()
export class Localization implements OnDestroy {
  public languages: { code: string; path: string; name: string }[] = [
    // { code: 'da', path: 'da', name: 'Dansk' },
    // implementation example: { code: 'de', path: 'de', name: 'Deutsch' },
    // { code: 'el', path: 'el', name: 'Ελληνικά' },
    { code: 'en', path: 'en', name: 'English' },
    { code: 'de', path: 'de', name: 'Deutsch' }
    // { code: 'en-GB', path: 'en-GB', name: 'English (UK)' },
    // { code: 'es', path: 'es', name: 'Español' },
    // { code: 'fr', path: 'fr', name: 'Français' },
    // { code: 'he_IL', path: 'he_IL', name: 'עִברִית' },
    // { code: 'it', path: 'it', name: 'Italiano' },
    // { code: 'nl', path: 'nl', name: 'Nederlands' },
    // { code: 'nb_NO', path: 'nb_NO', name: 'Norsk' },
    // { code: 'fi', path: 'fi', name: 'Suomi' },
    // { code: 'tr', path: 'tr', name: 'Türkçe' }
  ];
  private subRoute: Subscription | undefined;

  constructor(private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document) {
    this.translate.setDefaultLang('template');
    this.translate.onLangChange.subscribe(this.storeLang);
    // registerLocaleData(localeEnGb);
    // registerLocaleData(localeEnGb, 'template');
    registerLocaleData(localeEn);
    // implementation example: registerLocaleData(localeDe);
    registerLocaleData(localeDe);
    // registerLocaleData(localeFr);
    // registerLocaleData(localeDa);
    // registerLocaleData(localeNl);
    // registerLocaleData(localeFi);
    // registerLocaleData(localeIt);
    // registerLocaleData(localeNb);
    // registerLocaleData(localeTr);
    // registerLocaleData(localeEs);
    // registerLocaleData(localeHe);
    // registerLocaleData(localeEl);

    if (environment.envName !== 'prod') {
      this.languages.push({ code: 'template', path: 'template', name: 'LANG_40.ENGLISH_DEV' });
    }
  }
  public ngOnDestroy(): void {
    if (this.subRoute) {
      this.subRoute.unsubscribe();
    }
  }

  public detectLanguage() {
    let urlLanguage;
    this.subRoute = this.activatedRoute.queryParams.subscribe(params => {
      urlLanguage = params['lang'];
      const browserLanguage = this.translate.getBrowserCultureLang();
      const localLanguage = this.loadLang();
      const lang = localLanguage ? localLanguage : browserLanguage;
      const queryParamLang = urlLanguage ? urlLanguage : lang;
      if (this.languageIsSupported(queryParamLang)) {
        this.translate.use(queryParamLang);
      } else {
        this.translate.use('en');
      }
    });


  }

  public setUserLanguage(language: string) {
    if (this.languageIsSupported(language)) {
      this.translate.use(language);
    }
  }

  public loadLang(): string | undefined {
    return localStorage['lang'];
  }

  private languageIsSupported(language: string) {
    const arrayofLanguages = this.languages.map(item => item.code);
    return arrayofLanguages.includes(language);
  }

  private storeLang(event: LangChangeEvent) {
    localStorage['lang'] = event.lang;
    if (this.document) {
      this.document.documentElement.lang = event.lang;
    }
  }

}
