import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../../../../../shared/model/user.model';
import { SelectionType } from '@siemens/ngx-datatable';
import { ModalRef, SI_DATATABLE_CONFIG } from '@simpl/element-ng';

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss']
})
export class UserPickerComponent {
  @Output() readonly selectUser: EventEmitter<User> = new EventEmitter();
  @Input() userList: User[] | undefined;
  @Input() modalRef: ModalRef | undefined;
  selectionType = SelectionType;
  selected: User[] = [];
  tableConfig = SI_DATATABLE_CONFIG;
  pageNumber = 0;
  indexUser = (index: number) => index;

  onSelectUser(user: User) {
    this.selectUser.emit(user);
  }

}
