export const environment = {
  production: true,
  apiMode: 'cloud',
  test: false,
  myvar: 'true',
  useHash: false,
  clientId: 'rLZPKwXdQb8TPdcKPgcDJFBrAcajjOQU',
  audience: 'https://horizon.siemens.com',
  issuer: 'https://siemens-bt-015.eu.auth0.com/',
  msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
  profileUrl: 'https://uss.login.siemens.com/userprofile',
  product: 'SystemDataSync',
  siemensIdBaseUrl: 'https://login.siemens.com',
  tenant: 'main-tenant-oidc',
  apiUrl: 'https://user.horizoncloud.io/api/v1',
  apiUrlV2: 'https://um.user.horizoncloud.io/api/v2',
  aodsUrl: 'https://aods.horizoncloud.io/api/v2',
  licenseApiUrl: 'https://subscription.horizoncloud.io/api',
  subscriptionUrl: 'https://subscription.horizoncloud.io/api/v2',
  accountManagerAppUrl: 'https://account.bpcloudapps.siemens.com',
  assetManagerAppUrl: 'https://assets.bpcloudapps.siemens.com',
  appSwitcherSource: 'https://webapp.horizoncloud.io/api/v1/me/apps',
  cookieUrl: '//assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-5f57f1252476.min.js',
  isChinaDeployment: '',
  aMapApiKey: '',
  googleAPIChannel: '107',
  googleMapsApiKey: 'AIzaSyA-sSzcLt_aaEuIEblwzQrDnFifJhucpXM',
  locationVerticalPath: 'https://product-int.locations.horizonint.cloud/v2/',
  projectApi: 'https://installation-projects-api.cerberus.horizonint.cloud/api/v0/',
  csvImporter: 'https://csv-importer.cerberus.horizonint.cloud/api/v0/',
  dwgImporter: 'https://dwg-importer.cerberus.horizondev.cloud/api/v0/',
  dwgExtractor: 'https://lt5jkhe6s6.execute-api.eu-west-1.amazonaws.com/dev/api/v0/',
  pipelineEnvironment: 'prod',
  get envName(){
    if(!this.pipelineEnvironment || this.pipelineEnvironment === 'local'){
      return 'dev';
    }
    return this.pipelineEnvironment.split('/')[0].split('.').pop();
  }
};
