import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppAccessService } from '../../services/app-access.service';
import { Router } from '@angular/router';
import { NavbarItem } from '@simpl/element-ng';
import { SessionService } from '../../services/session.service';
import { environment } from '../../../environments/environment';
import { CustomerChangeService } from '../../services/customer-change.service';
import { filter } from 'rxjs';
import { SiAppDataService } from '@building-x/common-ui-ng';
// import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './app-unauthorized.component.html',
  styleUrls: ['./app-unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit, OnDestroy {

  pageType?: string;
  appName = environment.product;
  companyName = '';
  appList: NavbarItem[] = [];
  checkAccess$: any;
  loadingData = true;
  accountManagerUrl = `${environment.accountManagerAppUrl}/#/sso/standard-plan`;
  userName?: string;

  constructor(private readonly appAccessService: AppAccessService,
    private readonly sessionService: SessionService,
    private readonly siAppDataService: SiAppDataService,
    private readonly customerChangeService: CustomerChangeService,
    private readonly router: Router) {
  }

  ngOnInit() {
    this.customerChangeService.isCustomerLoaded.pipe(filter(data => data)).subscribe(() => {
      this.loadingData = true;
      this.checkAccess$ = this.appAccessService.hasAccess.subscribe(value => {
        if (!value.hasAccess && value.unauthorizedType === '') {
          this.loadingData = true;
        } else {
          this.loadData(value);
        }
      });
    });
  }

  loadData(value: any) {
    this.sessionService.accountInfo.subscribe(data => {
      this.userName = data.title;
    });
    this.companyName = this.siAppDataService.selectedCustomer.attributes.name;
    this.appList = this.siAppDataService.appList;
    if (value.hasAccess) {
      this.router.navigateByUrl('[/]');
    } else {
      this.pageType = value.unauthorizedType;
    }
    this.loadingData = false;
  }

  ngOnDestroy() {
    if (this.checkAccess$) {
      this.checkAccess$.unsubscribe();
    }
  }
}
