import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploadConfig, SiFileUploaderComponent, UploadFile } from '@simpl/element-ng';
import { CsvImportService } from '../../../../shared/services/csv-import.service';
import { UploadInfo } from '../../../../shared/model/upload-info.model';
import { HttpHeaders } from '@angular/common/http';
import { DwgImportService } from '../../../../shared/services/dwg-import.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html'
})
export class FileUploaderComponent implements OnInit, AfterViewInit {

  @Output() readonly uploadCompleted: EventEmitter<UploadInfo> = new EventEmitter<UploadInfo>();

  @Input() partitionId: string | undefined;
  @Input() projectUploadType: 'csv' | 'dwg' | undefined;

  @ViewChild('uploader') uploader?: SiFileUploaderComponent;

  accept = '';
  uploadConfig: FileUploadConfig = {
    method: 'PUT',
    url: '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({ 'Content-Type': 'binary/octet-stream' }),
    fieldName: 'upload_file'
  };

  file: UploadFile | undefined;
  private uploadInfo: UploadInfo | undefined;

  constructor(private readonly csvImportService: CsvImportService,
    private readonly dwgImportService: DwgImportService) {
  }

  ngOnInit(): void {
    this.accept = `*.${this.projectUploadType}`;
  }
  ngAfterViewInit(): void {
    if (this.uploader?.dropZone) {
      this.uploader.dropZone.filesAdded.subscribe(uploadedFiles => this.onFileAdded(uploadedFiles));
    }
  }

  onUploaded(event: any) {
    this.uploadCompleted.emit(this.uploadInfo);
  }

  onFileAdded(addedFiles: UploadFile[]) {
    this.file = addedFiles[0];
    if (this.projectUploadType === 'csv' && this.partitionId) {
      this.csvImportService.getCsvUploadInfo(this.file.fileName, this.partitionId).subscribe(uploadInfo => {
        this.updateUploadInfo(uploadInfo);
      });
    } else {
      // DWG upload
      if (this.projectUploadType === 'dwg' && this.partitionId) {
        this.dwgImportService.getDwgUploadInfo(this.file.fileName, this.partitionId).subscribe(uploadInfo => {
          this.updateUploadInfo(uploadInfo);
        });
      }
    }
  }

  private updateUploadInfo(uploadInfo: UploadInfo) {
    if (uploadInfo) {
      this.uploadInfo = uploadInfo;
      this.uploadInfo.filename = this.file?.fileName;
      this.uploadConfig = {
        method: 'PUT',
        url: this.uploadInfo.attributes.presignedURL,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: new HttpHeaders({ 'Content-Type': 'binary/octet-stream' }),
        fieldName: 'upload_file',
        sendBinary: true
      };
    }
  }
}
