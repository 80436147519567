import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from './pages/main/main.page';
import { LoginPageComponent } from './pages/login/login.page';
import { AuthGuardService } from './services/auth-guard.service';
import { LogoutPageComponent } from './pages/logout/logout.page';
import { AboutComponent } from './pages/about/about.component';
import { HelpComponent } from './pages/help/help.component';
import { SingleSignOnComponent } from '@building-x/common-ui-ng';
import { UnauthorizedComponent } from './pages/app-unauthorized/app-unauthorized.component';
import { HomeComponent } from './pages/home/home.component';
import { LoopsComponent } from './pages/loops/loops.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { HistoryComponent } from './pages/history/history.component';
import { TeamComponent } from './pages/team/team.component';
import { FloorplansComponent } from './pages/floorplans/floorplans.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { MeResolver } from './shared/guards/me.resolver';
import { ProjectListComponent } from './pages/project-list/project-list.component';

const routes: Routes = [
  {
    path: '', component: MainPageComponent,
    canActivate: [AuthGuardService],
    resolve: {
      me: MeResolver
    },
    children: [
      { path: 'about', component: AboutComponent },
      { path: 'help', component: HelpComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'projects', component: ProjectListComponent },
      { path: '', redirectTo: 'projects', pathMatch: 'full' },
      {
        path: 'projects/:projectId', component: ProjectDetailsComponent,
        children: [
          { path: 'home', component: HomeComponent },
          { path: 'floorplans', component: FloorplansComponent },
          { path: 'loops', component: LoopsComponent },
          { path: 'messages', component: MessagesComponent },
          { path: 'inventory', component: InventoryComponent },
          { path: 'history', component: HistoryComponent },
          { path: 'team', component: TeamComponent },
          { path: 'settings', component: SettingsComponent }
        ]
      }
    ]
  },
  { path: 'login', component: LoginPageComponent },
  { path: 'logout', component: LogoutPageComponent },
  { path: 'sso', component: SingleSignOnComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
