import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly sessionService: SessionService,
    private readonly router: Router
  ) { }

  canActivate() {
    return this.sessionService.initialized.pipe(
      tap(signedIn => !signedIn && this.router.navigate(['/login']))
    );
  }
}
