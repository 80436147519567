<div class="container-fluid p-0">
  <div class="d-flex justify-content-between  px-2 ms-10 me-10 mt-10">
    <h2 class="page-top-title text-Primary"><strong>{{ 'FORM_ITEM_40.PROJECTS' | translate }}</strong></h2>
  </div>

  <div class="d-flex justify-content-between pb-5 px-2 ms-10 me-10 mt-4">
    <div class="row">
      <div class="col-auto">
        <si-search-bar placeholder="{{ 'PROJECTS_LIST.FILTER_PLACEHOLDER' | translate }}"
          (searchChange)="updateFilter(false, $event)" [debounceTime]="500" [showIcon]="true">
        </si-search-bar>

      </div>

      <div class="col-auto align-self-center">
        <si-switch id="showArchivedProjects" (toggle)="updateFilter(true, $event)"></si-switch>
        <span>
          {{ (notArchivedOnly$ | async) ? ("PROJECTS_LIST.SHOW"| translate) : ("PROJECTS_LIST.HIDE" | translate) }}
        </span>
      </div>

    </div>

    <div class="row">
      <div class="col-4">
        <button type="button" class="btn btn-primary me-5" (click)="openModalWizard(createproj, 'modal-lg')">
          <i class="icon element-plus"></i>{{ 'SHARED.CREATE' | translate}}
        </button>
      </div>
    </div>
  </div>

  <app-projects-list-table [projects]="projects$ | async" [err]="projectsError$ | async"></app-projects-list-table>
</div>

<ng-template #createproj let-modalRef="modalRef">
  <app-create-project-wizard [modalRef]="modalRef" class="modal-body"></app-create-project-wizard>
</ng-template>
