import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { AttributeType } from '../../../../shared/model/attribute-type.model';
import { ATTRIBUTES_TYPES } from '../shared/attributes-types';
import { AttributesWithForm } from '../shared/import-data-with-form.model';

@Component({
  selector: 'app-dwg-attributes',
  templateUrl: './dwg-attributes.component.html',
  styleUrls: ['./dwg-attributes.component.scss']
})
export class DwgAttributesComponent {

  @Input() attributesWithForm?: AttributesWithForm;

  attributeTypes: AttributeType[] = ATTRIBUTES_TYPES;

  identifyAttributeType(index: string, attributeType: AttributeType) {
    return attributeType.name;
  }

  identifyAttributes(index: string, attribute: AbstractControl) {
    return index;
  }

  get attributesFormArray() {
    return this.attributesWithForm?.form.get('attributes') as FormArray;
  }
}
