import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { AttributeNameAndExampleValue, Block, Layer, Layout } from '../../../../shared/model/dwg-asset.model';
import { ATTRIBUTES_TYPES } from '../shared/attributes-types';
import { DEVICE_TYPES } from '../shared/device-types';
import { AttributesWithForm, DwgAssetWithForms } from '../shared/import-data-with-form.model';
import { Location } from '../../../../shared/model/location.model';

@Component({
  selector: 'app-dwg-report',
  templateUrl: './dwg-report.component.html',
  styleUrls: ['./dwg-report.component.scss']
})
export class DwgReportComponent {

  @Input() dwgAssetWithForm?: DwgAssetWithForms;
  @Input() attributesWithForm?: AttributesWithForm;
  @Input() floors?: Location[];

  @Output() readonly importTriggered: EventEmitter<undefined> = new EventEmitter<undefined>();

  deviceTypes = DEVICE_TYPES;
  attributeTypes = ATTRIBUTES_TYPES;

  get blocksFormArray() {
    return this.dwgAssetWithForm?.blocksWithForm.form.get('blocks') as FormArray;
  }

  get attributesFormArray() {
    return this.attributesWithForm?.form.get('attributes') as FormArray;
  }

  get layoutsFormArray() {
    return this.dwgAssetWithForm?.layoutsWithForm.form.get('layouts') as FormArray;
  }

  identifyLayouts(index: string, layouts: Layout) {
    return layouts?.layoutName;
  }

  identifyLayers(index: string, layers: Layer) {
    return layers.name;
  }

  identifyBlocks(index: string, block: Block) {
    return block.name;
  }

  identifyAttributes(index: string, attr: AttributeNameAndExampleValue) {
    return attr.name;
  }

  getFloorLabel(id: string) {
    const item = this.floors?.find(f => f.id === id);
    if (item) {
      return item.attributes.label;
    }
    return '';
  }

  getBlockTypeLabel(id: string) {
    const item = this.deviceTypes.find(d => d.id === id);
    if (item) {
      return item.name;
    }
    return '';
  }

  getAttributeTypeLabel(id: string) {
    const item = this.attributeTypes.find(a => a.id === id);
    if (item) {
      return item.name;
    }
    return '';
  }

}
