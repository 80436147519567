import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, of, pipe } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FX40i_GROUP_FX40i_GROUP_PARTITION_ID } from '../../../app.constant';
import { BuildingFloors } from '../../../shared/model/building-floors.model';
import { DwgAssetInfo } from '../../../shared/model/dwg-asset.model';
import { DwgSvgAssetInfo, LayoutSvg } from '../../../shared/model/dwg-svg-asset.model';
import { Project } from '../../../shared/model/project.model';
import { UploadInfo } from '../../../shared/model/upload-info.model';
import { DwgImportService } from '../../../shared/services/dwg-import.service';
import { LocationService } from '../../../shared/services/location.service';

interface LocationInfo {
  partitionId: string;
  locationId: string;
}

interface ImportDwgState {
  project?: Project;
  uploadInfo?: UploadInfo;
  dwgAssets?: DwgAssetInfo;
  dwgAssetsError?: string;
  dwgSvgAssets?: DwgSvgAssetInfo[];
  dwgSvgAssetsError?: string;
  buildingsFloors?: BuildingFloors[];
  buildingsFloorsError?: string;
  dwgImportResult?: boolean;
  dwgImportError?: string;
}

@Injectable()
export class ImportDwgStore extends ComponentStore<ImportDwgState> {

  fakePartitionId = FX40i_GROUP_FX40i_GROUP_PARTITION_ID;

  constructor(private readonly dwgImportService: DwgImportService,
    private readonly locationService: LocationService) {
    super({
      project: undefined,
      uploadInfo: undefined,
      dwgAssets: undefined,
      dwgAssetsError: undefined,
      dwgSvgAssets: undefined,
      dwgSvgAssetsError: undefined,
      buildingsFloors: undefined,
      buildingsFloorsError: undefined,
      dwgImportResult: undefined,
      dwgImportError: undefined
    });
  }

  readonly dwgAssets$ = this.select(state => state.dwgAssets);
  readonly dwgAssetsError$ = this.select(state => state.dwgAssetsError);

  readonly dwgSvgAssets$ = this.select(state => state.dwgSvgAssets);
  readonly dwgSvgAssetsError$ = this.select(state => state.dwgSvgAssetsError);

  readonly buildingsFloors$ = this.select(state => state.buildingsFloors);
  readonly buildingsFloorsError$ = this.select(state => state.buildingsFloorsError);

  readonly dwgImportResult$ = this.select(state => state.dwgImportResult);
  readonly dwgImportError$ = this.select(state => state.dwgImportError);

  readonly setProject = this.updater((state, project: Project) => ({
    ...state,
    project
  }));

  readonly setUploadInfo = this.updater((state, uploadInfo: UploadInfo) => ({
    ...state,
    uploadInfo
  }));

  getDwgAsset = this.effect((uploadInfo$: Observable<UploadInfo>) => uploadInfo$.pipe(
    tap({
      next: () => this.patchState({ dwgAssets: undefined, dwgAssetsError: undefined })
    }),
    switchMap(uploadInfo =>
      this.dwgImportService.getDwgAsset(this.fakePartitionId/*this.get().project.partitionId */, uploadInfo).pipe(
        tap({
          next: dwgAssets => this.patchState({ dwgAssets }),
          error: dwgAssetsError => this.patchState({ dwgAssetsError })
        }),
        catchError(() => EMPTY)
      )
    ))
  );

  loadProjectBuildingsFloors() {
    const projectLocation = this.get().project?.relationships.hasLocation?.data;
    const partitionId = this.get().project?.partitionId;
    if (projectLocation && partitionId) {
      const locationId = projectLocation[0].id;
      this.loadBuildingsFloors({ partitionId, locationId });
    }
  }

  loadBuildingsFloors = this.effect((info$: Observable<LocationInfo>) => info$.pipe(
    tap({
      next: () => this.patchState({ buildingsFloors: undefined, buildingsFloorsError: undefined })
    }),
    switchMap(info =>
      this.locationService.getBuildingsFloors(info.partitionId, info.locationId).pipe(
        tap({
          next: buildingsFloors => this.patchState({ buildingsFloors }),
          error: buildingsFloorsError => this.patchState({ buildingsFloorsError })
        }),
        catchError(() => EMPTY)
      )
    ))
  );

  getDwgSvgAsset = this.effect((latyouSvgBodyReq$: Observable<LayoutSvg>) => latyouSvgBodyReq$.pipe(
    tap({
      next: () => this.patchState({ dwgSvgAssets: undefined, dwgSvgAssetsError: undefined })
    }),
    switchMap(latyouSvgBodyReq =>
      this.dwgImportService.getSvgDwgAsset(this.fakePartitionId, this.get().uploadInfo, latyouSvgBodyReq).pipe(
        tap({
          next: dwgSvgAssets => this.patchState({ dwgSvgAssets }),
          error: dwgSvgAssetsError => this.patchState({ dwgSvgAssetsError })
        }),
        catchError(() => EMPTY)
      )
    ))
  );

  importData = this.effect<void>(
    pipe(
      tap({
        next: () => this.patchState({ dwgImportResult: undefined, dwgImportError: undefined })
      }),
      switchMap(() =>
        of(true).pipe(
          tap({
            next: importResult => this.patchState({ dwgImportResult: importResult }),
            error: importResultError => this.patchState({ dwgImportError: importResultError })
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

}
