import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as packageJson from '../../../../package.json';
import { AboutModel } from '../../shared/model/about.model';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent {
  @Output() readonly aboutPageEvent = new EventEmitter();
  aboutData: AboutModel = {
    appIcon: 'assets/ios-marengo-chimney-sweeper-alt.png',
    appName: this.translateService.instant('APP.NAME'),
    ossFilePath: '/assets/readme-oss.txt',
    version: (packageJson as any)?.default?.version
  };

  constructor(private readonly translateService: TranslateService) {
  }
}
