import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { of, tap } from 'rxjs';

import { DeleteConfirmationDialogResult, ModalRef, SiActionDialogService, SiWizardComponent } from '@simpl/element-ng';
import { TranslateService } from '@ngx-translate/core';
import { UploadInfo } from '../../../shared/model/upload-info.model';
import { ImportCsvStore } from './import-csv.store';

@Component({
  selector: 'app-import-csv-wizard',
  templateUrl: './import-csv-wizard.component.html',
  styleUrls: ['./import-csv-wizard.component.scss'],
  providers: [ImportCsvStore]
})
export class ImportCsvWizardComponent implements OnInit {
  @Input() modalRef: ModalRef | undefined;
  @Input() partitionId?: string;

  @ViewChild('wizard') wizard?: SiWizardComponent;

  uploadInfo?: UploadInfo;
  importSucceeds?: boolean;

  csvAssetsReport$ = this.csvImportStore.csvAssetsReport$;
  csvAssetsError$ = this.csvImportStore.csvAssetsError$;
  importHasBeenTriggered = false;

  csvImportReport$ = this.csvImportStore.csvImportReport$.pipe(tap(res => this.importSucceeds = res));
  csvImportError$ = this.csvImportStore.csvImportError$;

  constructor(
    private readonly csvImportStore: ImportCsvStore,
    private siModal: SiActionDialogService,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    if (this.partitionId) {
      this.csvImportStore.setPartitionId(this.partitionId);
    }
  }

  cancel() {
    this.siModal.showDeleteConfirmationDialog(
      this.translateService.instant('WIZARD.CANCEL_CONFIRM_MSG'),
      this.translateService.instant('WIZARD.CANCEL_CONFIRM_HEAD'),
      this.translateService.instant('WIZARD.CANCEL_CONFIRM_LEAVE'),
      undefined
    )
      .subscribe(result => {
        switch (result) {
          case DeleteConfirmationDialogResult.Delete:
            this.modalRef?.hide();
            break;
          case DeleteConfirmationDialogResult.Cancel:
            break;
        }
      });
  }

  onUploadCompleted(uploadInfo: UploadInfo) {
    this.uploadInfo = uploadInfo;
    setTimeout(() => {
      if (this.wizard) {
        this.wizard.next(1);
      }
    });
  }

  firstStepAfterUpload() {
    if (this.uploadInfo) {
      this.csvImportStore.getCsvReport(of(this.uploadInfo));
    }
  }

  onImportTriggered() {
    this.importHasBeenTriggered = true;
    setTimeout(() => {
      if (this.wizard) {
        this.wizard.next(1);
      }
    });
    this.csvImportStore.importData();
  }

  onCompletion() {
    this.modalRef?.hide();
  }
}


