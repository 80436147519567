import {
  Component, TemplateRef
} from '@angular/core';
import { SiModalService } from '@simpl/element-ng';
import { ProjectListStore } from './project-list.store';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
  host: { class: 'p-5' }

})
export class ProjectListComponent {

  projects$ = this.projectListStore.filteredProjects$;
  projectsError$ = this.projectListStore.projectsError$;
  notArchivedOnly$ = this.projectListStore.notArchivedOnly$;

  constructor(
    public projectListStore: ProjectListStore,
    private modalService: SiModalService
  ) {}

  updateFilter(isArchivedChanged: boolean, event?: any) {
    if (isArchivedChanged) {
      this.projectListStore.setFilter({ notArchivedOnly: event });
    } else {
      if (typeof event === 'string') {
        const searchValue = event.toLowerCase();
        this.projectListStore.setFilter({ searchString: searchValue });
      }
    }
  }

  openModalWizard(template: TemplateRef<any>, modalClass?: string) {
    this.modalService.show(template, {
      ignoreBackdropClick: true,
      keyboard: true,
      animated: true,
      class: modalClass,
      ariaLabelledBy: 'sample-modal-title'
    });
  }
}
