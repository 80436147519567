import { AbstractControl, FormArray, FormBuilder, FormControl, ValidatorFn } from '@angular/forms';
import { AttributeNameAndExampleValue, Block, Layer, Layout } from '../../../../shared/model/dwg-asset.model';
import { LayoutSvg } from '../../../../shared/model/dwg-svg-asset.model';
import { UploadInfo } from '../../../../shared/model/upload-info.model';
import { AttributesWithForm, BlocksWithForm, DwgAssetWithForms, LayersWithForm, LayoutWithForm } from './import-data-with-form.model';
export class DwgImportUtils {

  static minSelectedCheckboxes(min = 1): ValidatorFn {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      let totalSelected = 0;
      if (formArray instanceof FormArray) {
        totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
          .map(control => control.value)
        // total up the number of checked checkboxes
          .reduce((prev, next) => next ? prev + next : prev, 0);
      }
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  static mergeAttributesAndExamples(first: AttributeNameAndExampleValue[],
    second: AttributeNameAndExampleValue[]): AttributeNameAndExampleValue[] {
    const res: AttributeNameAndExampleValue[] = [...first];
    second.forEach(attr => {
      const itemIndex = res.findIndex(a => a.name === attr.name);
      if (itemIndex < 0) {
        res.push(attr);
      } else {
        if (res[itemIndex].exampleValue === '' &&
        attr.exampleValue !== '') {
          res[itemIndex].exampleValue = attr.exampleValue;
        }
      }
    });
    return res;
  }

  static createAttributeControl(formBuilder: FormBuilder) {
    return formBuilder.group({
      selected: [false],
      attributeType: ['']
    });
  }

  static createLayoutControl(formBuilder: FormBuilder) {
    return formBuilder.group({
      selected: [false],
      building: [''],
      floor: ['']
    });
  }

  static createBlockControl(formBuilder: FormBuilder) {
    return formBuilder.group({
      selected: [false],
      deviceType: ['']
    });
  }

  static createLayoutsWithForm(formBuilder: FormBuilder, layouts?: Layout[]) {
    const layoutsArray = layouts ? layouts : [];
    const res = {
      form: formBuilder.group({
        layouts: new FormArray([] /* DwgImportUtils.minSelectedCheckboxes(1)*/)
      }),
      list: layoutsArray
    };
    if (layouts) {
      layouts.forEach(() => {
        (res.form.controls.layouts as unknown as FormArray).push(DwgImportUtils.createLayoutControl(formBuilder));
      });
    }
    return res;
  }

  static createLayersWithForm(formBuilder: FormBuilder, layers?: Layer[]) {
    const layersArray = layers ? layers : [];
    const res = {
      form: formBuilder.group({
        layers: new FormArray([], DwgImportUtils.minSelectedCheckboxes(1))
      }),
      list: layersArray
    };
    if (layers) {
      layers.forEach(lay => {
        (res.form.controls.layers as unknown as FormArray).push(new FormControl(lay.isActive === true));
      });
    }
    return res;
  }

  static createBlocksWithForm(formBuilder: FormBuilder, blocks?: Block[]): BlocksWithForm {
    const blocksArray = blocks ? blocks : [];
    const res = {
      form: formBuilder.group({
        blocks: new FormArray([] /*DwgImportUtils.minSelectedCheckboxes(1)*/)
      }),
      list: blocksArray
    };
    if (blocks) {
      blocks.forEach(block => {
        (res.form.controls.blocks as any).push(DwgImportUtils.createBlockControl(formBuilder));
      });
    }
    return res;
  }

  static createSvgBodyReq(layoutsWithForm: LayoutWithForm, layersWithForm: LayersWithForm, uploadInfo: UploadInfo): LayoutSvg {
    const result: LayoutSvg = {
      type: 'LayoutSvg',
      id: uploadInfo.id,
      attributes: {
        layersIds: [],
        layoutIds: []
      }
    };

    const layoutsControls = (layoutsWithForm.form.get('layouts') as FormArray).controls;

    if (layoutsControls) {
      layoutsControls.forEach((layoutControl, index) => {
        if (layoutControl.get('selected')?.value === true) {
          result.attributes.layoutIds.push(layoutsWithForm.list[index].layoutName);
        }
      });
    }

    const layers = (layersWithForm.form.get('layers') as FormArray).controls;

    if (layers) {
      layers.forEach((layerControl, index) => {
        if (layerControl.value === true) {
          result.attributes.layersIds.push(layersWithForm.list[index].name);
        }
      });
    }
    return result;
  }

  static createAttributesForm(dwgAssetWithForm: DwgAssetWithForms | undefined, formBuilder: FormBuilder) {
    let attributesWithForm: AttributesWithForm | undefined;
    let attributesNamesAndExampleValues: AttributeNameAndExampleValue[] = [];
    const blocksWForms = dwgAssetWithForm?.blocksWithForm;
    if (blocksWForms?.form && blocksWForms?.list) {
      const blocksControls = (blocksWForms.form.controls['blocks'] as FormArray).controls;
      if (blocksControls) {
        blocksControls.forEach((blockControl, index) => {
          if (blockControl.get('selected')?.value === true) {
            attributesNamesAndExampleValues = DwgImportUtils.mergeAttributesAndExamples(attributesNamesAndExampleValues,
              blocksWForms?.list[index].attributesNamesAndExampleValues);
          }
        });
      }

      attributesWithForm = {
        form: formBuilder.group({
          attributes: new FormArray([] /*, DwgImportUtils.minSelectedCheckboxes(1)*/)
        }),
        list: attributesNamesAndExampleValues
      };
      attributesNamesAndExampleValues.forEach(attr => {
        (attributesWithForm?.form.controls['attributes'] as FormArray).push(DwgImportUtils.createAttributeControl(formBuilder));
      });
    }
    return attributesWithForm;
  }

  static createSelectedLayoutArray(layoutsWithForm: LayoutWithForm): Layout[] {
    const result: Layout[] = [];
    const layoutsControls = (layoutsWithForm.form.get('layouts') as FormArray).controls;
    if (layoutsControls && layoutsWithForm.list) {
      layoutsControls.forEach((layoutControl, index) => {
        if (layoutControl.get('selected')?.value === true) {
          result.push(layoutsWithForm.list[index]);
        }
      });
    }
    return result;
  }

}
