<div *ngIf="csvAssetsReport || err else loading">
  <table *ngIf="csvAssetsReport && !err" class="table table-sm elevation-1" aria-label="csv file summary">
    <tbody>
      <tr>
        <td class="h-25" style="width: 50%;">{{ 'CSV_REPORT.NUMBER_OF_PANELS'| translate}}</td>
        <td class="h-25">{{csvAssetsReport.numberOfPanels}}</td>
      </tr>
      <tr *ngFor="let panel of csvAssetsReport.numberOfLoopsPerPanel | keyvalue; trackBy: identifyPanel">
        <td class="h-25">{{ 'CSV_REPORT.PANEL'| translate}} {{panel.key}}</td>
        <td class="h-25"> {{panel.value}} {{ 'CSV_REPORT.LOOPS'| translate}}</td>
      </tr>
      <tr>
        <td class="h-25">{{ 'CSV_REPORT.NUMBER_OF_DEVICES'| translate}}</td>
        <td class="h-25">{{csvAssetsReport.numberOfDevices}}</td>
      </tr>
      <tr *ngFor="let floor of csvAssetsReport.numberOfDevicesPerFloor | keyvalue; trackBy: identifyFloor">
        <td class="h-25"> {{ 'SHARED.FLOOR'| translate}} {{floor.key}}</td>
        <td class="h-25">{{floor.value}} {{ 'CSV_REPORT.DEVICES'| translate}}</td>
      </tr>
    </tbody>
  </table>
  <table *ngIf="csvAssetsReport && !err" class="table table-sm elevation-1" aria-label="csv file device summary">
    <thead>
        <th class="h-25" style="width: 50%;">{{ 'CSV_REPORT.TYPE'| translate}}</th>
        <th class="h-25">{{ 'CSV_REPORT.NUMBER_OF_DETECTORS'| translate}}</th>
    </thead>
    <tbody>
      <tr *ngFor="let deviceType of csvAssetsReport.numberOfDevicesPerType | keyvalue; trackBy: identifyDevice">
        <td class="h-25">{{deviceType.key}}</td>
        <td class="h-25">{{deviceType.value}}</td>
      </tr>
    </tbody>
  </table>

  <si-empty-state *ngIf="err" icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_IMPORTING_CSV' | translate}}">
  </si-empty-state>
</div>
<div *ngIf="csvAssetsReport || err" class="d-flex justify-content-center">
  <button type="button" class="btn btn-primary me-6"
  [disabled]="err" (click)="importTriggered.emit()">{{ 'SHARED.IMPORT'| translate}}</button>
</div>

<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
