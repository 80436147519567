<div class="modal-header">
  <h4 class="modal-title" id="sample-modal-title">{{ 'USER_PICKER.SELECTED_USER' | translate }}</h4>
  <button type="button" class="btn btn-circle btn-sm btn-ghost element-cancel" aria-label="Close"
    (click)="modalRef?.hide()"> </button>
</div>
<div class="modal-body">
  <app-user-picker-header [selectedUser]="selected[0]"></app-user-picker-header>
  <ngx-datatable class="table-element elevation-1 im-users-data-table-height" [cssClasses]="tableConfig.cssClasses"
    [rows]="userList" [reorderable]="true" [headerHeight]="tableConfig.headerHeight" [footerHeight]="0"
    [rowHeight]="tableConfig.rowHeight" [externalPaging]="false" [limit]="8" [offset]="pageNumber"
    [selectionType]="selectionType.single" [virtualization]="false" [scrollbarV]="true" [selected]="selected"
    columnMode="force" [datatableInteractionAutoSelect]="true" siDatatableInteraction>
    <ngx-datatable-column [resizeable]="false" prop="avatar" [minWidth]="80" [maxWidth]="80" [flexGrow]="0.8"
      [draggable]="false" name="">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="name-container">
          <img [src]="row.displayPicture"
            *ngIf="row.displayPicture && row.displayPicture !== 'assets/images/account.svg'"
            class="im-profile-image small" alt="Profile Image" />
          <div class="im-profile-image small"
            *ngIf="!row.displayPicture || row.displayPicture === 'assets/images/account.svg'">
            {{ row.attributes.identity.fullName | nameInitials}}
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [resizeable]="false" [flexGrow]="2.5" [sortable]="true" prop="attributes.identity.fullName"
      [draggable]="false" name="{{ 'SHARED.NAME' | translate }}">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <strong title="{{ row.attributes.identity.fullName }}">{{ row.attributes.identity.fullName }}</strong>
        <br /> <span title="{{ row.attributes.identity.emailId }}">{{ row.attributes.identity.emailId }}</span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<div class="modal-footer bg-base-1 p-6 mb-6">
  <button type="button" class="btn btn-secondary" (click)="modalRef?.hide()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onSelectUser(selected[0])" [disabled]="!selected.length">{{
    'USER_PICKER.CONFIRM' | translate }}</button>
</div>
