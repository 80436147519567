<si-navbar #navbarRef
  [appName]="'APP.NAME'"
  [showSecondaryNavbar]="isSecondaryNavbarAvailable"
  [currentAppPermission]="'app.accountMgr'"
  [defaultHelpPortalURL]="'APP_CDP_URLS.DEFAULT'"
  [infoLinks]="{ items: [{ title: 'HORIZON_UI.ABOUT_PAGE.TITLE', link: '/about' }]}"
  [lang]="currentLanguage" (logout)="onLogout($event)" [showCreateClient]="false"
  [persistCustomerId]="persistCustomerId"
  [persistPartitionsIds]="persistPartitionIds"
  [enableOfflinePage]="true"
  [accountManagerUrl]="myProfile"
  [allowPartitionFilter]="true"
  [subMenu]="items"
  (isSwitcherOpened)="isSwitcherOpened($event)"
  (paramsData)="getParams($event)"
  (customerSelection)="customerSelection($event)"
  data-cy="navbar-component"
  [appSwitcherDataSource]="appSwitcherURL">
  <ng-content></ng-content>
  <div class="has-navbar-fixed-top" [ngClass]="isCustomerSwitcherOpened ? 'disable-ui' : ''">
    <router-outlet></router-outlet>
  </div>
</si-navbar>
