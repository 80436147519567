import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRestService } from './base-rest.service';
import { Me } from '../model/me.model';
import { environment } from '../../../environments/environment';
@Injectable()
export class MeRestService extends BaseRestService<Me> {
  public override path = `${environment.apiUrl}/me`;

  constructor(protected override http: HttpClient) {
    super(http);
  }

}
