import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, tap } from 'rxjs';
import { Me } from '../model/me.model';
import { MeRestService } from '../services/me-rest.service';
import { MeStore } from '../store/me.store';

@Injectable({
  providedIn: 'root'
})

export class MeResolver implements Resolve<Me> {

  constructor(private readonly meRest: MeRestService, private readonly meStore: MeStore) {
  }

  resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<Me> {
    return this.meRest.get().pipe(tap(me => this.meStore.setUser(me)));
  }
}
