import { Injectable } from '@angular/core';
import { SiAppDataService } from '@building-x/common-ui-ng';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, pipe } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';
import { Project } from '../../shared/model/project.model';
import { ProjectApiService } from '../../shared/services/project-api.service';
import { ProjectsFilter } from './shared/projects-filter.model';

interface ProjectListState {
  projects?: Project[];
  filteredProjects?: Project[];
  projectsError?: string;
  notArchivedOnly: boolean;
  searchValue: string;
}

@Injectable()
export class ProjectListStore extends ComponentStore<ProjectListState> {

  constructor(private readonly projectService: ProjectApiService,
    private readonly siAppDataService: SiAppDataService) {
    super({
      projects: undefined,
      filteredProjects: undefined,
      projectsError: undefined,
      notArchivedOnly: false,
      searchValue: ''
    });
  }

  readonly filteredProjects$ = this.select(state => state.filteredProjects);
  readonly projectsError$ = this.select(state => state.projectsError);
  readonly notArchivedOnly$ = this.select(state => state.notArchivedOnly);

  loadProjectList = this.effect<void>(
    pipe(
      tap(() => this.patchState({ projects: undefined, projectsError: undefined, notArchivedOnly: false, searchValue: undefined })),
      switchMap(() =>
        this.projectService.getProjectsFromPartitions(this.siAppDataService.selectedPartitions).pipe(
          concatMap(projects => this.projectService.getProjectsLocations(projects)),
          concatMap(projects => this.projectService.getProjectsInstallers(this.siAppDataService.selectedCustomer.id, projects)),
          tap({
            next: projects => this.patchState({ projects, filteredProjects: projects }),
            error: projectsError => this.patchState({ projectsError })
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  setFilter = this.updater((state, filter: ProjectsFilter) => {
    const notArchivedOnly = (filter.notArchivedOnly !== undefined) ? filter.notArchivedOnly : state.notArchivedOnly;
    let searchString = (filter.searchString !== undefined) ? filter.searchString : state.searchValue;
    searchString = (searchString === undefined) ? '' : searchString; // due componentstore changes '' to undefined
    return {
      ...state,
      notArchivedOnly,
      searchValue: searchString,
      filteredProjects: state.projects?.filter(p =>
        (this.isArchivedPassed(p, notArchivedOnly)) &&
        (this.isSearchPassed(p, searchString))
      )
    };
  });

  private isProjectArchived(p: Project) {
    return p.attributes.status.toLowerCase().includes('archived');
  }

  private isArchivedPassed(p: Project, archiveFilter: boolean) {
    return (!this.isProjectArchived(p) || archiveFilter === false);
  }

  private isSearchPassed(p: Project, searchValue: string): boolean {
    return (searchValue === '' || p.attributes.name.toLowerCase().includes(searchValue));
  }
}
