<p class="si-h1">{{ 'WIZARD.SELECT_LAYERS'| translate}}</p>
<hr>
<form
 *ngIf="dwgAssetWithForm else loading"
  [formGroup]="dwgAssetWithForm.layersWithForm.form">
  <si-form-container [enableValidationHelp]="false"  [form]="dwgAssetWithForm.layersWithForm.form">
    <div class="container-fluid" si-form-container-content> 
      <ul class="layers-list pt-6 px-6">
        <div *ngFor="let layer of dwgAssetWithForm?.layersWithForm.list; let i = index; trackBy:identifyLayers">
          <si-form-item [hideBorder]="true" [labelWidth]="0" >
            <div class="form-control-has-icon"  formArrayName="layers" >
              <input type="checkbox" [formControlName]="i" id="layerName" class="form-check-input">
              <label class="form-check-label" for="layerName" translate>{{layer.name}}</label>
            </div>
          </si-form-item>
        </div>
      </ul>
    </div>
    </si-form-container>
</form>
<ng-template #notfound>
  <si-empty-state icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_MESSAGE4' | translate}}"
    content="{{'WIZARD.ERROR' | translate}}">
  </si-empty-state>
</ng-template>
<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
