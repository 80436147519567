import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-creation-result',
  templateUrl: './creation-result.component.html',
  styleUrls: ['./creation-result.component.scss']
})
export class CreationResultComponent {
  @Input() projectCreationReport?: boolean;
  @Input() err?: string;
}
