import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ProjectInfoUtils {

  public static dateValidator(): ValidatorFn | null {

    const dateDiffTimeValidator: ValidatorFn = (form: AbstractControl): ValidationErrors | null => {
      const start: Date = form.get('startDate')?.value;
      const end: Date = form.get('completionDate')?.value;
      if (start && end) {
        const isRangeValid = (end.getTime() - start.getTime() > 0);
        return isRangeValid ? null : { completionDateTime: true };
      }
      return null;
    };
    return dateDiffTimeValidator;
  }

}
