
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Partition } from '@building-x/common-ui-ng';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Address } from '../model/address.model';
import { BuildingFloors } from '../model/building-floors.model';
import { Location } from '../model/location.model';
import { PartitionLocations } from '../model/partition-locations.model';
import { AddressService } from './address.service';
import { BaseRestService } from './base-rest.service';
import { LocationUtils } from './location-utils';

const FILTER_TYPE = 'filter[type]';

@Injectable({
  providedIn: 'root'
})

export class LocationService extends BaseRestService<Location> {

  private readonly verticalPath = environment.locationVerticalPath;

  constructor(protected override readonly http: HttpClient,
    private readonly addressService: AddressService) {
    super(http);
  }

  getLocation(partitionId: string, locationId: string): Observable<Location> {
    super.path = `${this.verticalPath}partitions/${partitionId}/locations`;
    return super.getById(locationId);
  }

  getCampuses(partitionId: string): Observable<Location[]> {
    const campusesPath = `${this.verticalPath}partitions/${partitionId}/locations`;
    let params = new HttpParams();
    params = params.set(FILTER_TYPE, 'Campus');
    return super.findByPath<Location>(campusesPath, params).pipe(
      catchError((err: HttpErrorResponse) => {
        console.warn('cannot retreive Campuses for partition %s \n %s', partitionId, err.message);
        return of([]);
      })
    );
  }

  getBuildings(partitionId: string): Observable<Location[]> {
    const buildingsPath = `${this.verticalPath}partitions/${partitionId}/locations`;
    let params = new HttpParams();
    params = params.set(FILTER_TYPE, 'Building');
    return super.findByPath<Location>(buildingsPath, params).pipe(
      catchError((err: HttpErrorResponse) => {
        console.warn('cannot retreive Buildings for partition %s \n %s', partitionId, err.message);
        return of([]);
      })
    );
  }

  getLocationAddress(partitionId: string, locationId: string): Observable<Address> {
    return this.getLocation(partitionId, locationId).pipe(
      switchMap(location => this.addressService.getAddress(partitionId, location?.relationships?.hasPostalAddress?.data.id)
      )
    );
  }

  getBuildingsFloors(partitionId: string, locationId: string): Observable<BuildingFloors[]> {
    return this.getLocation(partitionId, locationId).pipe(
      switchMap(location => this.getLocationBuildingsFloors(partitionId, location))
    );
  }

  getPartitionsLocations(partitions: Partition[]): Observable<PartitionLocations[]> {
    let res: PartitionLocations[] = LocationUtils.setUpPartitionLocations(partitions);
    const allCampuses$ = this.getPartitionsCampuses(partitions);
    const allBuildings$ = this.getPartitionsBuildings(partitions);
    return forkJoin([allCampuses$, allBuildings$]).pipe(
      map(allPartitionsCampusesBuildings => {
        res.forEach((partition, index) => {
          res[index].locations = LocationUtils.processCampusesBuildings(allPartitionsCampusesBuildings[0][index],
            allPartitionsCampusesBuildings[1][index]);
        });
        res = res.filter(pl => pl.locations && pl.locations.length > 0);
        return res;
      }));
  }

  private getPartitionsCampuses(partitions: Partition[]): Observable<Location[][]> {
    const obs: Observable<Location[]>[] = [];
    partitions.forEach(partition => {
      obs.push(this.getCampuses(partition.id));
    });
    return forkJoin(obs);
  }

  private getPartitionsBuildings(partitions: Partition[]): Observable<Location[][]> {
    const obs: Observable<Location[]>[] = [];
    partitions.forEach(partition => {
      obs.push(this.getBuildings(partition.id));
    });
    return forkJoin(obs);
  }

  /**
   * Get all floors by given buildingId
   * uses the filter[type] field to reduce results to single type
   * @param buildingId id of the building
   * */
  private getFloorsByBuildingId(partitionId: string, buildingId: string): Observable<Location[]> {
    const floorsPath = `${this.verticalPath}partitions/${partitionId}/locations`;
    let params = new HttpParams();
    params = params.set(FILTER_TYPE, 'Floor');
    params = params.set('filter[isPartOf.data.id]', buildingId);
    return super.findByPath<Location>(floorsPath, params);
  }

  private getLocationBuildingsFloors(partitionId: string, location: Location): Observable<BuildingFloors[]> {
    if (location.type === 'Building') {
      return this.getFloorsByBuildingId(partitionId, location.id).pipe(
        map(floors => {
          const res: BuildingFloors[] = [location];
          res[0].floors = floors;
          return res;
        })
      );
    } else {
      if (location.type === 'Campus') {
        return of([]);
      } else {
        console.log('getBuildingsFloors: invalid location.');
        return of([]);
      }
    }
  }

}
