import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CsvAssetInfo } from '../model/csv-asset.model';
import { UploadInfo } from '../model/upload-info.model';
import { BaseRestService } from './base-rest.service';


@Injectable({
  providedIn: 'root'
})

export class CsvImportService extends BaseRestService<UploadInfo> {

  public override path = `${environment.csvImporter}`;

  constructor(public override readonly http: HttpClient) {
    super(http);
  }

  getCsvUploadInfo(filename: string, partitionId: string): Observable<UploadInfo> {
    const params = new HttpParams().set('filename', filename);
    this.path = `${environment.csvImporter}partitions/${partitionId}/csv-upload`;
    return super.get(params);
  }

  getCsvAsset(partitionId: string | undefined, uploadInfo: UploadInfo): Observable<CsvAssetInfo[]> {
    if (uploadInfo.filename && partitionId) {
      const params = new HttpParams().set('filename', uploadInfo.filename);
      const assetsPath = `${environment.csvImporter}partitions/${partitionId}/csv-asset/${uploadInfo.id}`;
      return super.findByPath(assetsPath, params);
    } else {
      return of([]);
    }
  }
}
