  <si-wizard #wizard [hasCancel]="true" (cancel)="cancel();" [enableCompletionPage]="true" completionPageVisibleTime="3000"
  [completionText]="'WIZARD.DWG_IMPORT_COMPLETED'| translate"
  (completionAction)="onCompletion()">

    <si-wizard-step heading="{{ 'WIZARD.UPLOAD_PROJECT'| translate}}" (next)="firstStepAfterUpload()" [isValid]="uploadInfo">
      <app-file-uploader [partitionId]="fakePartitionId" [projectUploadType]="'dwg'"
        (uploadCompleted)="onUploadCompleted($event)">
      </app-file-uploader>
    </si-wizard-step>

    <!-- dwg layouts-->
    <si-wizard-step heading="{{ 'WIZARD.SELECT_LAYOUTS'| translate}}" [isValid]="dwgAssetWithForm?.layoutsWithForm.form.valid">
      <app-dwg-layouts *ngIf="uploadInfo" [dwgAssetWithForm]="dwgAssetWithForm$ | async" [dwgAssetsError]="dwgAssetsError$ | async"
        [projectBuildings]="buildingsFloors$ | async" [projectBuildingsError]="buildingsFloorsError$ | async" [currentFloors]="currentFloors$ | async">
      </app-dwg-layouts>
    </si-wizard-step>
    <!-- dwg layers -->
    <si-wizard-step heading="{{ 'WIZARD.SELECT_LAYERS'| translate}}" [isValid]="dwgAssetWithForm?.layersWithForm.form.valid" (next)="getSvgs()">
      <app-dwg-layers *ngIf="dwgAssetWithForm" [dwgAssetWithForm]="dwgAssetWithForm">
      </app-dwg-layers>
    </si-wizard-step>
    <!-- dwg svg -->
    <si-wizard-step heading="{{ 'WIZARD.CHECK_SVGS'| translate}}">
      <app-dwg-svg-exports [dwgSvgAsset]="dwgSvgAsset$ | async"
      [dwgSvgAssetError]="dwgSvgAssetsError$ | async" [selectedLayouts]="selectedLayouts"></app-dwg-svg-exports>
    </si-wizard-step>
    <!-- dwg blocks -->
    <si-wizard-step  heading="{{ 'WIZARD.SELECT_BLOCKS'| translate}}" [isValid]="dwgAssetWithForm?.blocksWithForm.form.valid" (next)="createAttributesWithForm()">
      <app-dwg-blocks *ngIf="dwgAssetWithForm" [dwgAssetWithForm]="dwgAssetWithForm">
      </app-dwg-blocks>
    </si-wizard-step>
    <!-- dwg attributes -->
    <si-wizard-step  heading="{{ 'WIZARD.SELECT_ATTRIBUTES'| translate}}" [isValid]="attributesWithForm?.form.valid">
      <app-dwg-attributes *ngIf="attributesWithForm" [attributesWithForm]="attributesWithForm"></app-dwg-attributes>
    </si-wizard-step>

    <si-wizard-step heading=" {{ 'WIZARD.REVIEW'| translate}}">
      <app-dwg-report *ngIf="attributesWithForm" [dwgAssetWithForm]="dwgAssetWithForm" [attributesWithForm]="attributesWithForm"
      [floors]="currentFloors$ | async" (importTriggered)="onImportTriggered()"></app-dwg-report>
    </si-wizard-step>

    <!--dwg import results-->
    <si-wizard-step heading="{{ 'WIZARD.IMPORT_RESULTS'| translate}}" [isValid]="importSucceeds">
      <app-dwg-import-result [dwgImportResult]="dwgImportResult$ | async"
       [err]="dwgImportError$ | async">
      </app-dwg-import-result>
    </si-wizard-step>
  </si-wizard>

  <ng-template #notfound>
    <si-empty-state icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_MESSAGE4' | translate}}"
      content="{{'WIZARD.ERROR' | translate}}">
    </si-empty-state>
  </ng-template>

  <ng-template #loading>
    <si-loading-spinner></si-loading-spinner>
  </ng-template>
