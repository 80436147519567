import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string, letters?: number): unknown {
    let initials = '';
    const initialsCount = letters ?? 2;
    for (let i = 0; i < value?.split(' ').length; i++) {
      initials = initials + value?.split(' ')[i].slice(0, 1).toUpperCase();
      if (i === (initialsCount - 1)) { // return only first `initalCount` chars of initials
        break;
      }
    }
    return initials || '?';
  }

}
