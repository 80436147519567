<div class="modal-title" id="sample-modal-title">
  <si-wizard #wizard [hasCancel]="true" (cancel)="cancel();"
  [enableCompletionPage]="true" completionPageVisibleTime="3000"
  [completionText]="'WIZARD.CSV_IMPORT_COMPLETED'| translate"
  (completionAction)="onCompletion()">
    <si-wizard-step heading="{{ 'WIZARD.UPLOAD_PROJECT'| translate}}" (next)="firstStepAfterUpload()" [isValid]="uploadInfo">
      <app-file-uploader [projectUploadType]="'csv'" [partitionId]="partitionId"
        (uploadCompleted)="onUploadCompleted($event)">
      </app-file-uploader>
    </si-wizard-step>
    <!--csv assets report-->
    <si-wizard-step heading="{{ 'WIZARD.REVIEW'| translate}}" [isValid]="importHasBeenTriggered">
      <app-csv-upload-report [csvAssetsReport]="csvAssetsReport$ | async"
       [err]="csvAssetsError$ | async" (importTriggered)="onImportTriggered()"></app-csv-upload-report>
    </si-wizard-step>
    <!--csv import report-->
    <si-wizard-step heading="{{ 'WIZARD.IMPORT_RESULTS'| translate}}" [isValid]="importSucceeds">
      <app-csv-import-report [csvImportReport]="csvImportReport$ | async"
       [err]="csvImportError$ | async">
      </app-csv-import-report>
    </si-wizard-step>
  </si-wizard>

  <ng-template #notfound>
    <si-empty-state icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_MESSAGE4' | translate}}"
      content="{{'WIZARD.ERROR' | translate}}">
    </si-empty-state>
  </ng-template>

  <ng-template #loading>
    <si-loading-spinner></si-loading-spinner>
  </ng-template>
</div>

