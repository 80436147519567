
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from '../model/address.model';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Project } from '../model/project.model';
import { BaseRestService } from './base-rest.service';
import { LocationService } from './location.service';
import { UserService } from './user.service';
import { User } from '../model/user.model';
import { Partition } from '@building-x/common-ui-ng';


@Injectable({
  providedIn: 'root'
})

export class ProjectApiService extends BaseRestService<Project[]> {

  private readonly verticalPath = `${environment.projectApi}`;
  constructor(protected override readonly http: HttpClient,
    private readonly locationService: LocationService,
    private readonly userService: UserService) {
    super(http);
  }

  public getProjects(partitionId: string): Observable<Project[]> {
    return this.http.get<any>(`${this.verticalPath}partitions/${partitionId}/projects`).pipe(
      map(response => {
        const projects = this.unwrapResponse(response);
        projects.forEach(p => p.partitionId = partitionId);
        return projects;
      }),
      catchError((err: HttpErrorResponse) => {
        console.warn('cannot retreive installation projects for partition %s \n %s', partitionId, err.message);
        return of([]);
      })
    );
  }

  getProjectsFromPartitions(partitions: Partition[]): Observable<Project[]> {
    const obs: Observable<Project[]>[] = [];
    partitions.forEach(partition => {
      obs.push(this.getProjects(partition.id));
    });

    return forkJoin(obs).pipe(
      map(partitionsProjects => partitionsProjects.flat(1)));
  }

  getProjectsLocations(projectsList: Project[]): Observable<Project[]> {
    const obs: Observable<Address>[] = [];
    projectsList.forEach(project => {
      if (project.relationships.hasLocation.data) {
        obs.push(this.locationService.getLocationAddress(project.partitionId, project.relationships.hasLocation.data[0].id));
      }
    });

    if (obs.length > 0) {
      return forkJoin(obs).pipe(
        map(addresses => {
          projectsList.forEach((project, index) => {
            if (project.relationships.hasLocation.data) {
              project.addressLabel =
            `${addresses[index].attributes.street}, ${addresses[index].attributes.postalCode}
            ${addresses[index].attributes.locality}`;
            }
          });
          return projectsList;
        }));
    } else {
      return of(projectsList);
    }
  }

  getProjectsInstallers(customerId: string, projectsList: Project[]): Observable<Project[]> {
    const obs: Observable<User>[] = [];
    projectsList.forEach(project => {
      if (project.relationships.hasInstaller.data?.[0].id) {
        obs.push(this.userService.getUser(customerId, project.relationships.hasInstaller.data[0].id));
      }
    });

    if (obs.length > 0) {
      return forkJoin(obs).pipe(
        map(users => {
          projectsList.forEach((project, index) => {
            if (project.relationships.hasInstaller.data) {
              project.installerLabel = users[index].attributes.identity.fullName;
            }
          });
          return projectsList;
        }));
    } else {
      return of(projectsList);
    }
  }

  createProject(project: Project, partitionId: string) {
    return this.http.post<any>(`${this.verticalPath}partitions/${partitionId}/projects`, project);
  }

}
