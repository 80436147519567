<si-wizard #wizard [hasCancel]="true" (cancel)="cancel();" saveText="{{ 'WIZARD.FINISH'| translate}}">
  <si-wizard-step heading="{{'WIZARD.SELECT_LOCATION'| translate}}"
    [isValid]="partitionLocationsWithForm?.form.valid">
    <app-location-selection [partitionLocationsWithForm]="partitionLocationsWithForm$ | async"
    [currentLocations]="partitionLocationsStream$ | async"></app-location-selection>
  </si-wizard-step>
  <si-wizard-step heading="{{'WIZARD.INFORMATION'| translate}}" [isValid]="projectInfoForm.valid"  (next)="projecCreationTriggered()">
    <app-project-info [address]="selectedAddressStream$ | async" [projectInfo]="projectInfoForm"></app-project-info>
  </si-wizard-step>

    <!--proj creation report-->
    <si-wizard-step heading="{{ 'WIZARD.PROJECT_CREATION_RESULTS'| translate}}"  (save)="onCompletion()" [isValid]="createSucceeds">
      <app-creation-result [projectCreationReport]="projectCreationReport$ | async"
       [err]="projectCreationError$ | async">
      </app-creation-result>
    </si-wizard-step>
</si-wizard>

<ng-template #notfound>
  <si-empty-state icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_MESSAGE4' | translate}}"
    content="{{'WIZARD.ERROR' | translate}}">
  </si-empty-state>
</ng-template>

<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
