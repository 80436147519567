import { Injectable } from '@angular/core';
import { Customer, OneTrustCookieTypes, SiCookiePolicyService, SiCookiesService } from '@building-x/common-ui-ng';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerChangeService {

  customerRef = '';
  customerName = '';
  public customerChanged = false;
  private readonly isCustomerSwitch = new BehaviorSubject(this.customerChanged);
  public isCustomerLoaded = this.isCustomerSwitch.asObservable();
  constructor(private readonly cookieService: SiCookiesService, private readonly cookiePolicyService: SiCookiePolicyService) { }

  customerLoaded(orgLoaded: any) {
    this.isCustomerSwitch.next(orgLoaded);
  }


  switchCustomer(value: Customer | undefined) {
    if (value?.attributes?.name) {
      if (this.cookiePolicyService.permitted(OneTrustCookieTypes.Required)) {
        const cookieExpiry = new Date();
        cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
        this.cookieService.setCookie(
          'customerId',
          value.id,
          '',
          cookieExpiry.toUTCString()
        );
        this.cookieService.setCookie(
          'customerName',
          value?.attributes?.name,
          '',
          cookieExpiry.toUTCString()
        );
      }
      this.setCustomer(value.id, value?.attributes?.name);
    }
  }

  setCustomer(customerId: string, customerName: string) {
    this.customerName = customerName;
    this.customerRef = customerId;
  }
}
