<p class="si-h1">{{ 'WIZARD.REVIEW'| translate}}</p>
<hr>
<div *ngIf="dwgAssetWithForm?.layoutsWithForm.list" class="form-check mb-2 d-flex flex-column">
  <p class="si-h3 ">{{ 'WIZARD.FLOORPLAN_LAYOUT'| translate}}</p>
  <ul class="im-layers-list">
    <ng-container *ngFor="let layout of dwgAssetWithForm?.layoutsWithForm.list; let i = index; trackBy:identifyLayouts">
      <li *ngIf="layoutsFormArray.controls[i]?.get('selected')?.value === true">
        <span class="form-check-label">
          {{layout.layoutName}} - {{getFloorLabel(layoutsFormArray.controls[i]?.get('floor')?.value)}}</span>
      </li>
    </ng-container>
  </ul>
</div>
<div *ngIf="dwgAssetWithForm?.layersWithForm.list" class="form-check mb-2 d-flex flex-column col-md">
  <p class="si-h3 ">{{ 'WIZARD.LAYERS'| translate}}</p>
  <ul class="im-layers-list">
    <ng-container *ngFor="let layer of dwgAssetWithForm?.layersWithForm.list; let i = index; trackBy:identifyLayers">
      <li *ngIf="dwgAssetWithForm?.layersWithForm.form.get('layers').at(i).value">
        <span class="form-check-label"> {{layer.name}}</span>
      </li>
    </ng-container>
  </ul>
</div>
<div class="form-check mb-2 d-flex flex-column" *ngIf="dwgAssetWithForm?.blocksWithForm.list">
  <p class="si-h3 ">{{ 'WIZARD.BLOCK_DEVICETYPE'| translate}}</p>
  <ul class="im-layers-list">
    <ng-container *ngFor="let block of dwgAssetWithForm?.blocksWithForm.list; let i = index; trackBy:identifyBlocks">
      <li *ngIf="blocksFormArray.controls[i]?.get('selected')?.value === true">
        <span class="form-check-label">
          {{block.name}} - {{getBlockTypeLabel(blocksFormArray.controls[i]?.get('deviceType')?.value)}}</span>
      </li>
    </ng-container>
  </ul>
</div>
<div class="form-check mb-2 d-flex flex-column" *ngIf="attributesWithForm?.list">
  <p class="si-h3 ">{{ 'WIZARD.BLOCK_ATTRIBUTE_TYPE'| translate}}</p>
  <ul class="im-layers-list">
    <ng-container *ngFor="let attribute of attributesWithForm?.list; let i = index; trackBy:identifyAttributes">
      <li *ngIf="attributesFormArray.controls[i]?.get('selected')?.value === true">
        <span class="form-check-label">
          {{attribute.name}} -
          {{getAttributeTypeLabel(attributesFormArray.controls[i]?.get('attributeType')?.value)}}</span>
      </li>
    </ng-container>
  </ul>
</div>
<div *ngIf="dwgAssetWithForm" class="d-flex justify-content-center">
  <button type="button" class="btn btn-primary me-6"
  (click)="importTriggered.emit()">{{ 'SHARED.IMPORT'| translate}}</button>
</div>
