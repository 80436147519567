import { Injectable, NgModule, NgZone } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { SiAboutModule,
  SiDatepickerModule,
  SiFileUploaderModule,
  SiFilteredSearchModule,
  SiFormModule,
  SiLandingPageModule,
  SiLoadingSpinnerModule,
  SimplElementNgModule,
  SiNavbarModule,
  SiSearchBarModule,
  SiToastNotificationModule
} from '@simpl/element-ng';
import {
  SiAddressModule,
  SiAODSModule,
  SiAppDataService,
  SiAppListService,
  SiemensIdAuthenticationMockService,
  SiemensIdAuthenticationService,
  SiLoginAuthModule,
  SiPermissionService,
  SiProfileModule,
  SiProfileService,
  SiSharedModule,
  SiSubscriptionsModule,
  SiThemingService,
  SiUOModule
} from '@building-x/common-ui-ng';
import { AppRoutingModule } from './app-routing.module';
import { MainPageComponent } from './pages/main/main.page';
import { LoginPageComponent } from './pages/login/login.page';
import { LogoutPageComponent } from './pages/logout/logout.page';
import { SessionService } from './services/session.service';
import { TokenInterceptor } from './services/token.interceptor';
import { AboutComponent } from './pages/about/about.component';
import { HelpComponent } from './pages/help/help.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UnauthorizedComponent } from './pages/app-unauthorized/app-unauthorized.component';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { Localization } from './base/localization/localization.service';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { HomeComponent } from './pages/home/home.component';
import { FloorplansComponent } from './pages/floorplans/floorplans.component';
import { LoopsComponent } from './pages/loops/loops.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { HistoryComponent } from './pages/history/history.component';
import { TeamComponent } from './pages/team/team.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { windowProvider } from './shared/window-provider';
import { MeRestService } from './shared/services/me-rest.service';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { WizardWorkflowComponent } from './pages/project-list/create-project-wizard/create-project-wizard.component';
import { FileUploaderComponent } from './pages/project-list/shared/file-uploader/file-uploader.component';
import { DwgLayoutsComponent } from './pages/project-list/import-dwg-wizard/dwg-layouts/dwg-layouts.component';
import { DwgLayersComponent } from './pages/project-list/import-dwg-wizard/dwg-layers/dwg-layers.component';
import { DwgSvgExportsComponent } from './pages/project-list/import-dwg-wizard/dwg-svg-exports/dwg-svg-exports.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ProjectInfoComponent } from './pages/project-list/create-project-wizard/project-info/project-info.component';
import { LocationSelectionComponent } from './pages/project-list/create-project-wizard/location-selection/location-selection.component';
import { ProjectsListTableComponent } from './pages/project-list/projects-list-table/projects-list-table.component';
import { ImportDwgWizardComponent } from './pages/project-list/import-dwg-wizard/import-dwg-wizard.component';
import { CsvUploadReportComponent } from './pages/project-list/import-csv-wizard/csv-upload-report/csv-upload-report.component';
import { ImportCsvWizardComponent } from './pages/project-list/import-csv-wizard/import-csv-wizard.component';
import { DwgBlocksComponent } from './pages/project-list/import-dwg-wizard/dwg-blocks/dwg-blocks.component';
import { UserPickerComponent } from './pages/project-list/create-project-wizard/project-info/users-picker/user-picker.component';
import { ProjectListStore } from './pages/project-list/project-list.store';
import { DwgAttributesComponent } from './pages/project-list/import-dwg-wizard/dwg-attributes/dwg-attributes.component';
import { DwgReportComponent } from './pages/project-list/import-dwg-wizard/dwg-report/dwg-report.component';
import { CsvImportReportComponent } from './pages/project-list/import-csv-wizard/csv-import-report/csv-import-report.component';
import { InitialsPipe } from './shared/pipes/initials.pipe';
import {
  UserPickerHeaderComponent
} from './pages/project-list/create-project-wizard/project-info/users-picker/user-picker-header/user-picker-header.component';
import { MeStore } from './shared/store/me.store';
import { CreationResultComponent } from './pages/project-list/create-project-wizard/creation-result/creation-result.component';
import { DwgImportResultComponent } from './pages/project-list/import-dwg-wizard/dwg-import-result/dwg-import-result.component';

@Injectable()
export class GoogleMapsConfig implements LazyMapsAPILoaderConfigLiteral {
  public apiKey: string | undefined;
  public libraries: string[];
  public language: string;
  channel: string;

  constructor() {
    this.libraries = ['places'];
    this.language = localStorage['lang'] || 'template';
    this.channel = 'channel';
  }
}
export const siemensIdAuthenticationFactory = (
  oAuthService: OAuthService,
  ngZone: NgZone
) => {
  if (environment.test) {
    return new SiemensIdAuthenticationMockService(ngZone);
  } else {
    return new SiemensIdAuthenticationService(
      oAuthService,
      environment.issuer,
      environment.msIssuer,
      environment.profileUrl
    );
  }
};

export const httpLoaderFactory = (http: HttpClient) => new MultiTranslateHttpLoader(http, [
  { prefix: './assets/i18n/', suffix: '.json' },
  { prefix: './assets/i18n/imported/', suffix: '.json' },
  { prefix: './assets/i18n/no-translations/', suffix: '.json' },
  { prefix: './assets/i18n/imported/no-translations/', suffix: '.json' }

]);

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CarouselModule.forRoot(),
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    RouterModule,
    SiAboutModule,
    SiDatepickerModule,
    SiFileUploaderModule,
    SiFilteredSearchModule,
    SiLandingPageModule,
    SiLoadingSpinnerModule,
    SiLoginAuthModule,
    SimplElementNgModule,
    SiNavbarModule,
    SiSearchBarModule,
    SiSearchBarModule,
    SiSharedModule,
    SiToastNotificationModule,
    SiProfileModule.forRoot({
      apiUrl: `${environment.apiUrl}/me`,
      apiUrlV2: `${environment.apiUrlV2}/me`
    }),
    OAuthModule.forRoot(),
    AgmCoreModule.forRoot(),
    SiAddressModule.forRoot({
      aMapApiKey: environment.aMapApiKey,
      googleConfig: {
        apiKey: environment.googleMapsApiKey,
        libraries: ['places'],
        language: localStorage['lang'] || 'template',
        channel: environment.googleAPIChannel
      },
      isChinaDeployment: environment.isChinaDeployment === 'true' ? true : false
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SiSubscriptionsModule.forRoot({
      apiUrl: environment.licenseApiUrl,
      apiUrlV2: environment.subscriptionUrl,
      product: environment.product,
      noGoogleApi: environment.test
    }),
    SiAODSModule.forRoot({
      apiUrl: `${environment.aodsUrl}`
    }),
    SiUOModule.forRoot({
      apiUrl: environment.apiUrl,
      apiUrlV2: environment.apiUrlV2,
      product: environment.product,
      noGoogleApi: environment.test
    })
  ],
  exports: [TranslateModule],
  providers: [
    Localization,
    MeRestService,
    MeStore,
    ProjectListStore,
    SiAppDataService,
    SiAppListService,
    SiemensIdAuthenticationService,
    SiFormModule,
    SiPermissionService,
    SiProfileService,
    {
      provide: LAZY_MAPS_API_CONFIG,
      useClass: GoogleMapsConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: SiemensIdAuthenticationService,
      useFactory: siemensIdAuthenticationFactory,
      // next line is needed to be not in alphabetical order, otherwise it does not work
      // eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays
      deps: [OAuthService, NgZone]
    },
    SiThemingService,
    windowProvider
  ],
  declarations: [
    AboutComponent,
    AppComponent,
    CreationResultComponent,
    CsvImportReportComponent,
    CsvUploadReportComponent,
    DwgAttributesComponent,
    DwgBlocksComponent,
    DwgImportResultComponent,
    DwgLayersComponent,
    DwgLayoutsComponent,
    DwgReportComponent,
    DwgSvgExportsComponent,
    FileUploaderComponent,
    FloorplansComponent,
    HelpComponent,
    HistoryComponent,
    HomeComponent,
    ImportCsvWizardComponent,
    ImportDwgWizardComponent,
    InitialsPipe,
    InventoryComponent,
    LocationSelectionComponent,
    LoginPageComponent,
    LogoutPageComponent,
    LoopsComponent,
    MainPageComponent,
    MessagesComponent,
    NavbarComponent,
    ProjectDetailsComponent,
    ProjectInfoComponent,
    ProjectListComponent,
    ProjectListComponent,
    ProjectsListTableComponent,
    SettingsComponent,
    TeamComponent,
    UnauthorizedComponent,
    UserPickerComponent,
    UserPickerHeaderComponent,
    WizardWorkflowComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    sessionService: SessionService
  ) {
    sessionService.initialize();
  }
}
