import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NavbarItem } from '@simpl/element-ng';
import { Customer, OneTrustCookieTypes, Partition, SiAppDataService, SiCookiePolicyService, SiCookiesService,
  SiNavbarComponent
} from '@building-x/common-ui-ng';
import { AppAccessService } from '../../services/app-access.service';
import { config } from '../../../assets/app-config';
import { TranslateService } from '@ngx-translate/core';
import { CustomerChangeService } from '../../services/customer-change.service';
import { Localization } from '../../base/localization/localization.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProjectListStore } from '../../pages/project-list/project-list.store';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  persistCustomerId = '';
  currentLanguage = '';
  appSwitcherURL = environment.appSwitcherSource;
  public myProfile: any = config.myprofileURl;
  selectedCustomer?: Customer;
  partitions: Partition[] = [];
  isCustomerSwitcherOpened = false;
  public items: NavbarItem[] = [];

  isSecondaryNavbarAvailable = false;
  persistPartitionIds: string[] = [];
  @ViewChild('navbarRef') navbarRef?: SiNavbarComponent;
  private routerSubscription: Subscription | undefined;
  private projectDetailsRoute: ActivatedRoute | null = null;
  public appList?: NavbarItem[];

  constructor(private readonly cookieService: SiCookiesService,
    public readonly router: Router,
    private readonly siAppDataService: SiAppDataService,
    public readonly appAccessService: AppAccessService,
    public localizationService: Localization,
    private readonly customerChangeService: CustomerChangeService,
    private readonly cookiePolicy: SiCookiePolicyService,
    private readonly activatedRoute: ActivatedRoute,
    public translateService: TranslateService,
    public projectListStore: ProjectListStore
  ) {
    this.subscribeToRouterEvents();
  }

  ngOnInit() {
    // this read the persist id from cookie service & set in navbar.
    this.persistCustomerId = this.cookieService.getCookie('customerId');
    this.persistPartitionIds = this.cookieService.getCookie('persistPartitionIds')?.split(',');
    // read presisted lang & pass to navbar
    this.currentLanguage = this.translateService.currentLang;
    const savedLanguage = this.cookieService.getCookie('lang');
    if (savedLanguage) {
      this.translateService.use(savedLanguage);
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  onLogout(ev: any) {
    this.router.navigate(['/logout']);
  }

  // Read the params during sso when set in navbar like langauge, redirect urls
  getParams(params: any) {
    params.forEach((param: any) => {
      if (param.keyId === 'lang') {
        this.localizationService.setUserLanguage(param.value);

      }
      if (param.keyId === 'redirectTo') {
        this.router.navigateByUrl(param.value);
      }
    });
  }

  // Return event when customer switcher is opened
  isSwitcherOpened(event: any) {
    this.isCustomerSwitcherOpened = event;
  }

  customerSelection(eventData: any) {
    // if we want difference in partitions then remove this check
    if (eventData?.selectedCustomer && eventData?.selectedCustomer?.id !== this.selectedCustomer?.id) {
      // on customer change set false, so loader will get active
      this.customerChangeService.customerLoaded(false);
      this.persistPartitionIds = [];
      this.selectedCustomer = eventData.selectedCustomer;
      this.partitions = eventData.selectedPartitions;
      this.persistPartitionIds = eventData.selectedPartitions.map((partition: any) => partition.id);
      if (this.router.url.includes('?') && eventData.isCustomerChanged) {
        this.router
          .navigateByUrl(
            this.router.url.substring(0, this.router.url.indexOf('?'))
          )
          .then(data => this.router.navigate(['/projects'])); // remove query params first and then navigate
      } else if (eventData.isCustomerChanged) {
        this.router.navigate(['/projects']);
      }
      this.customerChangeService.switchCustomer(this.selectedCustomer);
      // set cookie
      if (this.cookiePolicy.permitted(OneTrustCookieTypes.Required)) {
        this.setCookie(eventData.selectedCustomer);
      }
      setTimeout(() => {
        this.loadAppData({ isLoaded: true, isSuccess: true });
        this.customerChangeService.customerLoaded(true);
      }, 50);
    }
  }


  loadAppData(ev: any) {
    if (ev.isLoaded) {
      if (this.siAppDataService.userAllCustomer.length) {
        this.checkAccess();
        this.isSecondaryNavbarAvailable = false;
        this.projectListStore.loadProjectList();
        // put app loading logic here
      } else {
        this.loadAdamUser([]);
      }
    }
  }

  loadAdamUser(orgList: any) {
    this.items = [];
    this.appAccessService.checkAccess(orgList);
    this.appList = [];
  }

  checkAccess() {
    this.appAccessService.checkAccess(this.siAppDataService.userAllCustomer);
  }

  setCookie(customer: Customer) {
    const cookieExpiry = new Date();
    cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
    this.cookieService.setCookie(
      'customerId',
      customer.id,
      '',
      cookieExpiry.toUTCString()
    );
    this.cookieService.setCookie(
      'customerName',
      customer?.attributes?.name,
      '',
      cookieExpiry.toUTCString()
    );

    this.cookieService.setCookie(
      'persistPartitionIds',
      this.persistPartitionIds.join(','),
      '',
      cookieExpiry.toUTCString()
    );
  }

  private subscribeToRouterEvents() {
    this.routerSubscription = this.router.events.subscribe((e: RouterEvent | any) => {
      if (e instanceof NavigationEnd) {
        if (this.router.url.lastIndexOf('/projects/') > -1) {
          this.projectDetailsRoute = this.activatedRoute.firstChild;
          this.isSecondaryNavbarAvailable = true;
          this.loadVerticalBarItemProject();
        }
      } else {
        this.isSecondaryNavbarAvailable = false;
      }
    });
  }

  private loadVerticalBarItemProject(): void {
    this.items = [{
      title: this.translateService.instant('SHARED.HOME'),
      id: this.translateService.instant('SHARED.HOME'),
      icon: 'element-home',
      link: './home',
      navigationExtras: { relativeTo: this.projectDetailsRoute },
      tooltip: this.translateService.instant('SHARED.HOME')
    }, {
      title: this.translateService.instant('SHARED.FLOORPLANS'),
      id: this.translateService.instant('SHARED.FLOORPLANS'),
      icon: 'element-map-location',
      link: './floorplans',
      navigationExtras: { relativeTo: this.projectDetailsRoute },
      tooltip: this.translateService.instant('SHARED.FLOORPLANS')
    }, {
      title: this.translateService.instant('SHARED.LOOPS'),
      id: this.translateService.instant('SHARED.LOOPS'),
      icon: 'element-control-loop',
      link: './loops',
      navigationExtras: { relativeTo: this.projectDetailsRoute },
      tooltip: this.translateService.instant('SHARED.LOOPS')
    }, {
      title: this.translateService.instant('SHARED.MESSAGES'),
      id: this.translateService.instant('SHARED.MESSAGES'),
      icon: 'element-notification',
      link: './messages',
      navigationExtras: { relativeTo: this.projectDetailsRoute },
      tooltip: this.translateService.instant('SHARED.MESSAGES')
    }, {
      title: this.translateService.instant('SHARED.INVENTORY'),
      id: this.translateService.instant('SHARED.INVENTORY'),
      icon: 'element-box',
      link: './inventory',
      navigationExtras: { relativeTo: this.projectDetailsRoute },
      tooltip: this.translateService.instant('SHARED.INVENTORY')
    }, {
      title: this.translateService.instant('SHARED.HISTORY'),
      id: this.translateService.instant('SHARED.HISTORY'),
      icon: 'element-timer',
      link: './history',
      navigationExtras: { relativeTo: this.projectDetailsRoute },
      tooltip: this.translateService.instant('SHARED.HISTORY')
    }, {
      title: this.translateService.instant('SHARED.TEAM'),
      id: this.translateService.instant('SHARED.TEAM'),
      icon: 'element-user-group',
      link: './team',
      navigationExtras: { relativeTo: this.projectDetailsRoute },
      tooltip: this.translateService.instant('SHARED.TEAM')
    }, {
      title: this.translateService.instant('SHARED.SETTINGS'),
      id: this.translateService.instant('SHARED.SETTINGS'),
      icon: 'element-settings',
      link: './settings',
      navigationExtras: { relativeTo: this.projectDetailsRoute },
      tooltip: this.translateService.instant('SHARED.SETTINGS')
    }
    ];
  }
}
