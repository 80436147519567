import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @Output() readonly helpPageEvent = new EventEmitter();

  ngOnInit() {
    this.helpPageEvent.emit();
  }
}
