import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Response { data: any }


@Injectable()
export class BaseRestService<T> {
  /**
   * Generic endpoint for the service
   */
  protected path = '/api';

  constructor(
    protected http: HttpClient
  ) { }

  public unwrapResponse<TT = T>(response: Response): TT {
    return response.data;
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.path}/${id}`);
  }

  /**
   * Get one object from the generic endpoint
   */
  public get(params?: HttpParams): Observable<T> {
    return this.http
      .get<Response>(`${this.path}`, { params })
      .pipe(
        map(data => this.unwrapResponse(data))
      );
  }

  /**
   * Get a list of objects from a configurable endpoint
   */
  public findByPath<TT>(path: string, params?: HttpParams): Observable<TT[]> {
    return this.http
      .get<Response>(path, { params })
      .pipe(
        map(data => this.unwrapResponse<TT[]>(data))
      );
  }

  /**
   * Get one object by id
   */
  public getById(id: string): Observable<T> | any {
    return this.http.get<Response>(`${this.path}/${id}`).pipe(
      map(response => this.unwrapResponse(response))
    );
  }

  public patch(id: string, object: T): Observable<T> {
    return this.http.patch<T>(`${this.path}/${id}`, object);
  }

  public put(id: string, object: T): Observable<T> {
    return this.http.put<T>(`${this.path}/${id}`, object);
  }

  public post(object: T): Observable<T> {
    return this.http.post<T>(`${this.path}`, object);
  }
}
