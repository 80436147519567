import { CsvAssetInfo } from '../../../../shared/model/csv-asset.model';
import { CsvAssetsReport } from '../models/csv-assets-report.model';

export class CsvReportUtils {

  public static aggregateData(assets: CsvAssetInfo[]): CsvAssetsReport {
    const result: CsvAssetsReport = {
      numberOfPanels: 0,
      numberOfDevices: 0,
      numberOfLoopsPerPanel: new Map<number, number>(),
      numberOfDevicesPerFloor: new Map<string, number>(),
      numberOfDevicesPerType: new Map<string, number>()
    };

    const loopsPerPanel = new Map<number, number[]>();
    assets.forEach(asset => {
      if (asset.attributes.panel) {
        CsvReportUtils.aggregateLoopsPanels(loopsPerPanel, asset, result);
      }
      if (asset.attributes.deviceModel) {
        CsvReportUtils.aggregateDevice(result, asset);
      }
      if (asset.attributes.floorLevel) {
        CsvReportUtils.aggregateDeviceFloor(result, asset);
      }
    });
    loopsPerPanel.forEach((loops, panel) => {
      result.numberOfLoopsPerPanel.set(panel, loops.length);
    });

    return result;
  }

  private static aggregateDevice(result: CsvAssetsReport, asset: CsvAssetInfo) {
    if (!result.numberOfDevicesPerType.has(asset.attributes.deviceModel)) {
      result.numberOfDevicesPerType.set(asset.attributes.deviceModel, 0);
    }
    result.numberOfDevices++;
    let nOfDeviceType = result.numberOfDevicesPerType.get(asset.attributes.deviceModel);
    if (nOfDeviceType !== undefined) {
      nOfDeviceType++;
      result.numberOfDevicesPerType.set(asset.attributes.deviceModel, nOfDeviceType);
    }
  }

  private static aggregateLoopsPanels(loopsPerPanel: Map<number, number[]>, asset: CsvAssetInfo, result: CsvAssetsReport) {
    if (!loopsPerPanel.has(asset.attributes.panel)) {
      result.numberOfPanels++;
      loopsPerPanel.set(asset.attributes.panel, []);
    }
    const loops = loopsPerPanel.get(asset.attributes.panel);
    if (loops && !loops.includes(asset.attributes.loop)) {
      loops.push(asset.attributes.loop);
    }
  }

  private static aggregateDeviceFloor(result: CsvAssetsReport, asset: CsvAssetInfo) {
    if (!result.numberOfDevicesPerFloor.has(asset.attributes.floorLevel.toString())) {
      result.numberOfDevicesPerFloor.set(asset.attributes.floorLevel.toString(), 0);
    }
    let nOfDevice = result.numberOfDevicesPerFloor.get(asset.attributes.floorLevel.toString());
    if (nOfDevice !== undefined) {
      nOfDevice++;
      result.numberOfDevicesPerFloor.set(asset.attributes.floorLevel.toString(), nOfDevice);
    }
  }

}


