import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, of, pipe } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CsvAssetInfo } from '../../../shared/model/csv-asset.model';
import { UploadInfo } from '../../../shared/model/upload-info.model';
import { CsvImportService } from '../../../shared/services/csv-import.service';
import { CsvAssetsReport } from './models/csv-assets-report.model';
import { CsvReportUtils } from './shared/csv-report-utils';

interface ImportCsvState {
  partitionId?: string;
  csvAssetsReport?: CsvAssetsReport;
  csvAssets?: CsvAssetInfo[];
  csvAssetsError?: string;
  csvImportReport?: boolean;
  csvImportError?: string;
}

@Injectable()
export class ImportCsvStore extends ComponentStore<ImportCsvState> {

  constructor(private readonly csvImportService: CsvImportService) {
    super({
      partitionId: undefined,
      csvAssetsReport: undefined,
      csvAssets: undefined,
      csvAssetsError: undefined,
      csvImportReport: undefined,
      csvImportError: undefined
    });
  }

  readonly csvAssetsReport$ = this.select(state => state.csvAssetsReport);
  readonly csvAssetsError$ = this.select(state => state.csvAssetsError);
  readonly csvAssets$ = this.select(state => state.csvAssets);

  readonly csvImportReport$ = this.select(state => state.csvImportReport);
  readonly csvImportError$ = this.select(state => state.csvImportError);

  readonly setPartitionId = this.updater((state, partitionId: string) => ({
    ...state,
    partitionId
  }));

  getCsvReport = this.effect((uploadInfo$: Observable<UploadInfo>) => uploadInfo$.pipe(
    tap({
      next: () => this.patchState({ csvAssetsReport: undefined, csvAssetsError: undefined })
    }),
    switchMap(uploadInfo =>
      this.csvImportService.getCsvAsset(this.get().partitionId, uploadInfo).pipe(
        tap(assets => this.patchState({ csvAssets: assets })),
        map(csvAssets =>
          CsvReportUtils.aggregateData(csvAssets)
        ),
        tap({
          next: csvAssetsReport => this.patchState({ csvAssetsReport }),
          error: csvAssetsError => this.patchState({ csvAssetsError })
        }),
        catchError(() => EMPTY)
      )
    ))
  );

  importData = this.effect<void>(
    pipe(
      tap({
        next: () => this.patchState({ csvAssetsReport: undefined, csvAssetsError: undefined })
      }),
      switchMap(() =>
        of(true).pipe(
          tap({
            next: importReport => this.patchState({ csvImportReport: importReport }),
            error: importReportError => this.patchState({ csvImportError: importReportError })
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );
}
