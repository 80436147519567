import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FX40i_GROUP_FX40i_GROUP_PARTITION_ID } from '../../app.constant';
import { Address } from '../model/address.model';
import { BaseRestService } from './base-rest.service';

@Injectable({
  providedIn: 'root'
})

export class AddressService extends BaseRestService<Address> {

  private readonly verticalPath = environment.locationVerticalPath;
  private readonly secondPath = `partitions/${FX40i_GROUP_FX40i_GROUP_PARTITION_ID}`;
  constructor(protected override readonly http: HttpClient) {
    super(http);
    super.path = `${this.verticalPath}${this.secondPath}/addresses`;
  }

  public getAddress(partitionId: string, addressId: string): Observable<Address> {
    super.path = `${this.verticalPath}partitions/${partitionId}/addresses`;
    return super.getById(addressId);
  }
}

