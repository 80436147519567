import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiFormContainerComponent, SiModalService } from '@simpl/element-ng';
import { Observable } from 'rxjs';
import { User } from '../../../../shared/model/user.model';
import { UserService } from '../../../../shared/services/user.service';
import { UserPickerComponent } from './users-picker/user-picker.component';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent {
  @Input() projectInfo?: FormGroup;
  @ViewChild(SiFormContainerComponent) formContainer: SiFormContainerComponent | undefined;
  userList$: Observable<User[]> | undefined;
  @Input() address?: string;

  identifyFormControl = (index: number) => index;
  labelWidth = '140';
  errorCodeTranslateKeyMap = new Map<string, string>([
    ['projectName.required', 'Project number is required'],
    ['completionDateTime', 'The end date time must be after start date'],
    ['installerId.required', 'Installer id is required'],
    ['engineerId.required', 'Engineer id is required'],
    ['projectManagerId.required', 'Project Manager id is required'],
    ['startDate.required', 'Start Date is required'],
    ['completionDate.required', 'Completion Date is required']
  ]);
  constructor(private modalService: SiModalService, private userService: UserService) {}
  get completionDateErrors() {
    const errors = this.formContainer?.getValidationErrors();
    return errors?.filter(error => error.errorCode === 'completionDateTime');
  }

  selectUserInstaller(newItem: User) {
    this.projectInfo?.patchValue({
      installerId: newItem.id,
      installerUser: `${newItem.attributes.identity.fullName}, ${newItem.attributes.identity.emailId}`
    });
  }

  selectUserEngineer(newItem: User) {
    this.projectInfo?.patchValue({
      engineerId: newItem.id,
      engineerUser: `${newItem.attributes.identity.fullName}, ${newItem.attributes.identity.emailId}`
    });
  }

  selectUserProjectManager(newItem: User) {
    this.projectInfo?.patchValue({
      projectManagerId: newItem.id,
      projectManagerUser: `${newItem.attributes.identity.fullName}, ${newItem.attributes.identity.emailId}`
    });
  }

  clearInstallerUser() {
    this.projectInfo?.patchValue({
      installerId: undefined,
      installerUser: undefined
    });
  }
  clearEngineerUser() {
    this.projectInfo?.patchValue({
      engineerId: undefined,
      engineerUser: undefined
    });
  }
  clearProjectManagerUser() {
    this.projectInfo?.patchValue({
      projectManagerId: undefined,
      projectManagerUser: undefined
    });
  }

  openModal(template?: TemplateRef<UserPickerComponent>) {
    if (!this.userList$) {
      this.userList$ = this.userService.getUsers();
    }
    if (template) {
      this.modalService.show(template, {
        ignoreBackdropClick: true,
        keyboard: true,
        animated: true,
        ariaLabelledBy: 'sample-modal-title'
      });
    }
  }

}
