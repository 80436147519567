import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CsvAssetsReport } from '../models/csv-assets-report.model';

@Component({
  selector: 'app-csv-upload-report',
  templateUrl: './csv-upload-report.component.html',
  styleUrls: ['./csv-upload-report.component.scss']
})
export class CsvUploadReportComponent {
  @Input() public csvAssetsReport?: CsvAssetsReport;
  @Input() err?: string;

  @Output() readonly importTriggered: EventEmitter<undefined> = new EventEmitter<undefined>();

  identifyPanel(index: string, panel: string) {
    return panel;
  }

  identifyDevice(index: string, deviceType: string) {
    return deviceType;
  }

  identifyFloor(index: string, floor: string) {
    return floor;
  }
}
