<div *ngIf="dwgImportResult || err else loading">
  <div *ngIf="dwgImportResult && !err" class="table elevation-1">
    OK
  </div>
  <si-empty-state *ngIf="err" icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_IMPORTING_CSV' | translate}}">
  </si-empty-state>

</div>
<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
