import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { Localization } from './base/localization/localization.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  cookieURL = environment.cookieUrl;

  constructor(public translate: TranslateService,
    public localization: Localization) {
    localization.detectLanguage();
    const lang = translate.currentLang;
    translate.use(lang);
  }
}
