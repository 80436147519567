import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FX40i_GROUP_FX40i_GROUP_CUSTOMER_ID } from '../../app.constant';
import { User } from '../model/user.model';
import { BaseRestService } from './base-rest.service';

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseRestService<User> {

  private readonly verticalPath = environment.apiUrlV2;
  private readonly secondPath = `/customers/${FX40i_GROUP_FX40i_GROUP_CUSTOMER_ID}`;
  constructor(protected override readonly http: HttpClient) {
    super(http);
    super.path = `${this.verticalPath}${this.secondPath}/users`;
  }
  public getUsers(): Observable<User[]> {
    return super.findByPath(this.path);
  }
  public getUser(customerId: string | undefined, userId: string): Observable<User> {
    super.path = `${this.verticalPath}/customers/${customerId}/users`;
    return super.getById(userId);
  }
}
