import { Component } from '@angular/core';
import { AVAILABLE_LANGUAGES } from '@building-x/common-ui-ng';

@Component({
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})

export class LoginPageComponent {
  availableLangs = AVAILABLE_LANGUAGES;
}
