<p class="si-h1">{{ 'WIZARD.CHECK_SVGS'| translate}}</p>
<hr>
<div *ngIf="dwgSvgAsset else loading">
  <carousel [interval]="0" [showIndicators]="true">
    <slide *ngFor="let asset of dwgSvgAsset; let i = index; trackBy:identifyDwgSvgAssetInfo">
      <div class="d-flex align-items-center im-slide-img-container">
        <img [src]="asset.attributes.presignedURL" [alt]="asset.id" class="im-slide-img">
      </div>
      <div class="carousel-caption im-slide-caption">
        <span *ngIf="selectedLayouts">{{selectedLayouts[i].layoutName}}</span>
      </div>
    </slide>
  </carousel>
</div>
<ng-template #notfound>
  <si-empty-state icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_MESSAGE4' | translate}}"
    content="{{'WIZARD.ERROR' | translate}}">
  </si-empty-state>
</ng-template>
<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
