import { Partition } from '@building-x/common-ui-ng';
import { Location } from '../model/location.model';
import { PartitionLocations } from '../model/partition-locations.model';

export class LocationUtils {

  public static processCampusesBuildings(campuses: Location[], buildings: Location[]): Location[] {
    const res: Location[] = [];
    campuses.forEach(c => {
      res.push(c);
      const campusBuildings = buildings.filter(b => b.relationships.isBuildingOf?.data.id ===
        c.id);
      res.push(...campusBuildings);
    });
    const aloneBuildings = buildings.filter(b => !b.relationships.isBuildingOf);
    res.push(...aloneBuildings);
    return res;
  }

  public static setUpPartitionLocations(partitions: Partition[]): PartitionLocations[] {
    const res: PartitionLocations[] = [];
    partitions.forEach(p => {
      const partName = (p.attributes.description) ? p.attributes.name + '-' + p.attributes.description :
        p.attributes.name;
      res.push({ id: p.id, name: partName, locations: [] });
    });
    return res;
  }

}
