import { Component } from '@angular/core';
@Component({
  selector: 'app-floorplans',
  templateUrl: './floorplans.component.html',
  styleUrls: ['./floorplans.component.scss'],
  host: { class: 'p-5' }
})

export class FloorplansComponent {

}
