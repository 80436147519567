import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-csv-import-report',
  templateUrl: './csv-import-report.component.html',
  styleUrls: ['./csv-import-report.component.scss']
})
export class CsvImportReportComponent {
  @Input() csvImportReport?: boolean;
  @Input() err?: string;
}
