<ng-container *ngIf="projects || err; else loading">
  <div class="ngx-datatable-wrapper px-2 ms-10 me-10 mt-4" *ngIf="projects && projects.length && !err else emptyTable">
    <ngx-datatable #datatable class="table-element elevation-1 scrollable-site-list" [rows]="projects"
      [limit]="config.pageSize" [cssClasses]="config.cssClasses" [headerHeight]="config.rowHeightSmall"
      [footerHeight]="config.footerHeight" [columnMode]="config.columnMode" [rowHeight]="'auto'"
      [externalPaging]="config.externalPaging" [offset]="currentPage" (activate)="onProjectClicked($event)"
      [selectionType]="selectionType">
      <ngx-datatable-column [minWidth]="65" prop="attributes.name" name="{{ 'SHARED.NAME' | translate }}"
        [sortable]="true" [draggable]="false" [resizeable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column prop="addressLabel" [minWidth]="70" name="{{ 'FORM_ITEM_40.LOCATION' | translate }}"
        [sortable]="true" [draggable]="false" [resizeable]="false">
        <ng-template let-row="row" let-value="value" let-i="index" ngx-datatable-cell-template>
          {{row.addressLabel}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="installerLabel" [minWidth]="70" name="{{ 'FORM_ITEM_40.INSTALLER' | translate }}"
        [sortable]="true" [draggable]="false" [resizeable]="false">
        <ng-template let-row="row" let-value="value" let-i="index" ngx-datatable-cell-template>
          {{row.installerLabel}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="attributes.percentageOfCompletion" [minWidth]="70"
        name="{{ 'FORM_ITEM_40.PROGRESS' | translate }}" [sortable]="true" [draggable]="false" [resizeable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <div class="im-si-progressbar">
            <si-progressbar heading="{{ 'FORM_ITEM_40.PROGRESS' | translate }}" [progress]=" value + ' %'" [max]="100"
              [value]="value"></si-progressbar>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [minWidth]="70" name="{{ 'FORM_ITEM_40.STATUS' | translate }}" [sortable]="true"
        [draggable]="false" [resizeable]="false" prop="attributes.status">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span *ngIf="value === 'New'" class="badge bg-caution">{{ 'FORM_ITEM_40.NEW' | translate }}</span>
          <span *ngIf="value === 'In progress'" class="badge bg-info">{{ 'FORM_ITEM_40.IN_PROGRESS' | translate
            }}</span>
          <span *ngIf="value === 'Complete'" class="badge bg-success">{{ 'FORM_ITEM_40.COMPLETE' | translate }}</span>
          <span *ngIf="value === 'Archived'" class="badge bg-default">{{ 'FORM_ITEM_40.ARCHIVED' | translate }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [minWidth]="30" [maxWidth]="60" [sortable]="true" [draggable]="false" [resizeable]="false" name="" prop="attributes.status">
        <ng-template let-row="row"  let-value="value" let-i="index"  ngx-datatable-cell-template>
            <si-content-action-bar *ngIf="value !== 'Archived' " [actionParam]="row" [secondaryActions]="itemActions"
            (click)="$event.preventDefault(); $event.stopPropagation();"></si-content-action-bar>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <div *ngIf="projects && projects.length > config.pageSize; else spacer" class="mt-4">
      <si-pagination [currentPage]="currentPage + 1" [totalRowCount]="projects && projects.length"
        [pageSize]="config.pageSize" (currentPageChange)="pageChanged($event)">
      </si-pagination>
    </div>
  </div>
  <ng-template #spacer>
    <div class="spacer"></div>
  </ng-template>
</ng-container>
<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
<ng-template #emptyTable>
  <si-empty-state *ngIf="err" icon="element-warning"
    heading="{{'ERROR_MESSAGE.ERROR_RETREIVING_PROJECTS' | translate}}">
  </si-empty-state>
  <si-empty-state *ngIf="!err"
      empty-content
      icon="element-plant"
      heading="{{'ERROR_MESSAGE.NO_DATA' | translate}}"
    ></si-empty-state>
</ng-template>

<ng-template #fromdwg let-modalRef="modalRef" let-thing="projectData">
  <app-import-dwg-wizard [modalRef]="modalRef" [project]="thing" class="modal-body">
  </app-import-dwg-wizard>
</ng-template>

<ng-template #fromcsv let-modalRef="modalRef" let-thing="projectData">
  <app-import-csv-wizard [modalRef]="modalRef" [partitionId]="thing.partitionId" class="modal-body"></app-import-csv-wizard>
</ng-template>
