/* eslint-disable @typescript-eslint/naming-convention */
export enum Permission {
  DmDeviceClaimOwnership = 'dm.device.claim-ownership',
  DmDeviceRead = 'dm.device.read',
  DmDeviceDelete = 'dm.device.delete',
  DmDeviceRenounceOwnership = 'dm.device.renounce-ownership',
  DmDeviceTrust = 'dm.device.trust',
  DmDeviceUpdate = 'dm.device.update',
  DmFeatureBootstarpRead = 'dm.feature.bootstrap.read',
  DmFeatureFidSelfTestExecute = 'dm.feature.fid.self-test.execute',
  DmFeatureFirmwareUpdateInstall = 'dm.feature.firmware-update.install',
  DmFeatureFirmwareUpdateRead = 'dm.feature.firmware-update.read',
  DmFeatureTunnelAccessTool = 'dm.feature.tunnel.access-tool',
  DmFeatureTunnelAccessWeb = 'dm.feature.tunnel.access-web',
  DmFeatureDeviceControlReboot = 'dm.feature.device-control.reboot',
  DmFeatureLocalUserResetPassword = 'dm.feature.local-users.reset-password',
  EamDeviceAssignmentRead = 'eam.device-assignment.read*',
  EamDeviceAssignmentUpdate = 'eam.device-assignment.update*',
  SiteLocCreate = 'site.loc.create',
  SiteLocDelete = 'site.loc.delete',
  SiteLocRead = 'site.loc.read',
  SiteLocUpdate = 'site.loc.update',
  SubscriptionLicenseActivate = 'subscription.license.activate',
  SubscriptionSubscriptionRead = 'subscription.subscription.read',
  UoInvitationCreate = 'uo.invitation.create',
  UoInvitationDelete = 'uo.invitation.delete',
  UoInvitationRead = 'uo.invitation.read',
  UoInvitationStateUpdate = 'uo.invitation.state.update',
  UoMembershipAccessGrantUpdate = 'uo.membership.access_grant.update',
  UoMembershipDelete = 'uo.membership.delete',
  UoMembershipRead = 'uo.membership.read',
  UoMembershipStateUpdate = 'uo.membership.state.update',
  UoOrganizationDelete = 'uo.organization.delete',
  UoOrganizationProfileUpdate = 'uo.organization.profile.update',
  UoOrganizationRead = 'uo.organization.read',
  UoOrganizationCreate = 'uo.organization.create',
  UoUserProfileUpdate = 'uo.user.profile.update',
  UoUserRead = 'uo.user.read',
  UoUserStateUpdate = 'uo.user.state.update',
  BETA_TESTER = 'fire.system.beta_test',
  FIRE_SITE_DELETE = 'fire.site.delete',
  FIRE_SITE_READ = 'fire.site.read',
  FIRE_SITE_UPDATE = 'fire.site.update',
  FIRE_TUNNEL_CREATE = 'fire.tunnel.create',
  FIRE_TEST_PLAN_READ = 'fire.testplan.read',
  FIRE_TEST_PLAN_CREATE = 'fire.testplan.create',
  FIRE_TEST_PLAN_UPDATE = 'fire.testplan.update',
  FIRE_TEST_PLAN_DELETE = 'fire.testplan.delete',
  UO_MEMBERSHIP_READ = 'uo.membership.read',
  UO_ORGANIZATION_READ = 'uo.organization.read',
  NOTIFICATION_ENROLLMENT_READ = 'nv.admin_enrollments.read',
  NOTIFICATION_ADMIN_RULES_READ = 'nv.admin_rules.read',
  NOTIFICATION_ENTITIES_READ_MUTE = 'nv.entities.mute_config_read',
  NOTIFICATION_ENTITIES_MUTE = 'nv.entities.mute_config',
  FIRE_NOTIFICATION_READ = 'fire.notification.read',
  APP_CERBERUS = 'app.cerberus',
  APP_ASSET_MANAGER = 'app.assetMgr',
  APP_ACCOUNT_MANAGER = 'app.accountMgr',
  APP_EDGE_MANAGER = 'app.edgeAppMgr',
  EAM_DEVICE_ASSIGNMENT_READ_RELEASED = 'eam.device-assignment.read-released',
  SUBSCRIPTION_LICENSE_ACTIVATE = 'subscription.license.activate',
  FIRE_INCIDENT_DETAILS_READ = 'fire.incident-details.read',
  ALARM_VERTICAL_READ = 'av.alarm.read',
  FIRE_CONFIG_ADT_TEST = 'fire.config.adt_test'
}

export const FX40i_GROUP_FX40i_GROUP_PARTITION_ID = 'cd2fb40b-be1e-4972-83d9-a6012817ad31';
export const FX40i_GROUP_FX40i_GROUP_CUSTOMER_ID = 'e3e36daf-582b-4e62-9339-3753854c6c87';

