import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { BaseAppAccessService, SiPermissionService } from '@building-x/common-ui-ng';
import { Permission } from '../app.constant';

export type UnauthorizedType = 'isAdamUser' | 'noPermission' | 'noAppCapability';

@Injectable({
  providedIn: 'root'
})

export class AppAccessService extends BaseAppAccessService {
  public readonly userAccessability: any = { hasAccess: false, unauthorizedType: '' };
  public readonly setAccess = new BehaviorSubject(this.userAccessability);
  public hasAccess = this.setAccess.asObservable();
  public unauthorizedPageRoute = '/unauthorized';
  constructor(private readonly router: Router,
    private readonly permissionService: SiPermissionService) {
    super();
  }
  // Put the minimum required permission for accessing app here
  hasAppPermission() {
    return this.permissionService.hasPermissionsInAny([Permission.UoOrganizationRead]);
  }

  hasOrgAccess(customer: any) {
    return customer.length ? true : false;
  }

  checkAccess(customerList: any) {
    if (this.hasOrgAccess(customerList)) {
      // if (this.hasAppPermission()) {
      this.setAccess.next({ hasAccess: true, unauthorizedType: '' });
      // } else {
      //   this.routeUnauthorizedPage('noPermission');
      // }
    } else {
      this.routeUnauthorizedPage('isAdamUser');
    }
  }

  routeUnauthorizedPage(type: string) {
    this.router.navigateByUrl(this.unauthorizedPageRoute, { state: { unauthorizedType: type } });
    this.setAccess.next({ hasAccess: false, unauthorizedType: type });
  }
}
