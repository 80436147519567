import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectionType } from '@siemens/ngx-datatable';
import { MenuItem, SiModalService } from '@simpl/element-ng';
import { Project } from '../../../shared/model/project.model';
import { projectListTableConfig } from './project-list-table-config';

@Component({
  selector: 'app-projects-list-table',
  templateUrl: './projects-list-table.component.html',
  styleUrls: ['./projects-list-table.component.scss']
})
export class ProjectsListTableComponent {
  @Input() projects: Project[] | undefined;
  @Input() err: string | undefined;
  @ViewChild('fromcsv') csvImport?: TemplateRef<any>;
  @ViewChild('fromdwg') dwgImport?: TemplateRef<any>;

  config = projectListTableConfig;
  currentPage = 0;
  selectionType = SelectionType.single;
  itemActions: MenuItem[] = [
    { title: 'Import CSV', action: projectData => this.openModal(this.csvImport, projectData) },
    { title: 'Import DWG', action: projectData => this.openModal(this.dwgImport, projectData) }
  ];

  constructor(
    private readonly router: Router,
    private modalService: SiModalService
  ) {}

  public pageChanged(event: any) {
    this.currentPage = event - 1;
  }

  public onProjectClicked(event: any): void {
    if (event.type === 'click') {
      this.router.navigate([`/projects/${event.row.projectId}/home`]);
    }
  }

  openModal(template: TemplateRef<any> | undefined, projectData: Project) {
    if (template) {
      this.modalService.show(template, {
        ignoreBackdropClick: true,
        keyboard: true,
        animated: true,
        class: 'modal-lg',
        ariaLabelledBy: 'sample-modal-title',
        initialState: { projectData }
      });
    }
  }

}
