import { FormGroup } from '@angular/forms';
import { PartitionLocationsWithForm } from '../../pages/project-list/create-project-wizard/shared/data-with-form.model';

export class ProjectUtility {
  static createProjectRequestBody(projectInfoForm: FormGroup, partitionLocationsWithForm: PartitionLocationsWithForm, userId: string) {
    const startdate = this.getEpoch(projectInfoForm.controls['startDate'].value);
    const completiondate = this.getEpoch(
      projectInfoForm.controls['completionDate'].value
    );
    return {
      projectId: '',
      type: 'InstallationType',
      partitionId: partitionLocationsWithForm?.form.controls['partitionId'].value,
      attributes: {
        creationDate: this.getEpoch(new Date()),
        endDate: 0,
        lastModifiedDate: 0,
        name: projectInfoForm.controls['projectName'].value,
        percentageOfCompletion: 0,
        plannedCompletionDate: completiondate,
        startDate: startdate,
        status: 'New'
      },
      relationships: {
        hasProjectManager: {
          data: [
            {
              type: 'User',
              id: projectInfoForm.controls['projectManagerId'].value
            }
          ]
        },
        hasLocation: {
          data: [
            {
              type: 'Location',
              id: partitionLocationsWithForm?.form.controls['locationId'].value
            }
          ]
        },
        hasPartition: {
          data: [
            {
              type: 'Partition',
              id: partitionLocationsWithForm?.form.controls['partitionId'].value
            }
          ]
        },
        hasEngineer: {
          data: [
            {
              type: 'User',
              id: projectInfoForm.controls['engineerId'].value
            }
          ]
        },
        hasInstallation: {
          data: [
            {
              type: 'Installation',
              id: ''
            }
          ]
        },
        hasInstaller: {
          data: [
            {
              type: 'User',
              id: projectInfoForm.controls['installerId'].value
            }
          ]
        },
        createdBy: {
          data: [
            {
              type: 'User',
              id: userId
            }
          ]
        },
        lastModifiedBy: {
          data: [
            {
              type: 'User',
              id: userId
            }
          ]
        }
      }
    };
  }

  static getEpoch(date: any) {
    const dateEpoch = new Date(date).getTime() / 1000;
    return dateEpoch;
  }
}
