<p class="si-h1">{{ 'WIZARD.SELECT_ATTRIBUTES'| translate}}</p>
<hr>
<form *ngIf="attributesWithForm else loading" [formGroup]="attributesWithForm.form">
  <si-form-container [form]="attributesWithForm.form" [enableValidationHelp]="false">
    <div class="container-fluid" si-form-container-content>
      <div class="row">
        <div class="col">
          <si-form-item [labelWidth]="0" [hideBorder]="true">
            <div class="form-check form-check-inline">
              <label for="">{{'SHARED.NAME'| translate}}</label>
            </div>
          </si-form-item>
        </div>
        <div class="col-3">
          <si-form-item [labelWidth]="0" [hideBorder]="true">
            <div class="form-check form-check-inline">
              <label for="value">{{ 'WIZARD.VALUE'| translate}}</label>
            </div>
          </si-form-item>
        </div>
        <div class="col">
          <si-form-item [labelWidth]="0" [hideBorder]="true">
            <div class="form-check form-check-inline">
              <label for="type">{{ 'WIZARD.TYPE'| translate}}</label>
            </div>
          </si-form-item>
        </div>
      </div>
      <div formArrayName="attributes"
        *ngFor="let attribute of attributesFormArray.controls; let i = index; trackBy:identifyAttributes">
        <div class="row">
          <div class="col">
            <si-form-item [hideBorder]="true" [labelWidth]="0">
              <div class="form-control-has-icon" [formGroupName]="i">
                <input type="checkbox" formControlName="selected" id="attributesName" class="form-check-input">
                <label class="form-check-label" for="attributesName"
                  translate>{{attributesWithForm.list[i].name}}</label>
              </div>
            </si-form-item>
          </div>
          <div class="col-3">
            <si-form-item [labelWidth]="0" [hideBorder]="true">
              <div class="form-check form-check-inline">
                <label for="">{{attributesWithForm.list[i].exampleValue}}</label>
              </div>
            </si-form-item>
          </div>
          <div class="col">
            <si-form-item [labelWidth]="0">
              <div [formGroupName]="i" class="form-control-has-icon w-100">
                <select aria-label="select Building" formControlName="attributeType" class="form-select">
                  <option value="">{{ 'WIZARD.SELECT_ATTRIBUTE_TYPE'| translate}}</option>
                  <ng-container *ngIf="attribute.get('selected').value === true">
                    <option *ngFor="let attrType of attributeTypes; trackBy:identifyAttributeType"
                      [value]="attrType.id">
                      {{attrType.name}}
                    </option>
                  </ng-container>
                </select>
                <i class="invalid-icon element-cancel-filled"></i>
              </div>
            </si-form-item>
          </div>
        </div>
      </div>
    </div>
  </si-form-container>

</form>
<ng-template #notfound>
  <si-empty-state icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_MESSAGE4' | translate}}"
    content="{{'WIZARD.ERROR' | translate}}">
  </si-empty-state>
</ng-template>
<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
