import { Component, Input } from '@angular/core';
import { User } from '../../../../../../shared/model/user.model';

@Component({
  selector: 'app-user-picker-header',
  templateUrl: './user-picker-header.component.html',
  styleUrls: ['./user-picker-header.component.scss']
})
export class UserPickerHeaderComponent {
  @Input() selectedUser: User | undefined;

}
