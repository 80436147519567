<p class="si-h1">{{ 'WIZARD.SELECT_LAYOUTS'| translate}}</p>
<hr>
<ng-container *ngIf="(dwgAssetWithForm && projectBuildings) || dwgAssetsError || projectBuildingsError else loading">
  <form *ngIf="(dwgAssetWithForm && projectBuildings)" 
  [formGroup]="dwgAssetWithForm.layoutsWithForm.form">
    <si-form-container #formContainer [form]="dwgAssetWithForm.layoutsWithForm.form" [enableValidationHelp]="false">
      <div class="container-fluid" si-form-container-content>
        <div class="row">
          <div class="col-3">
            <si-form-item [labelWidth]="0" [hideBorder]="true">
              <div class="form-check form-check-inline">
                <label for="layouts">{{ 'WIZARD.LAYOUTS'| translate}}</label>
              </div>
            </si-form-item>
          </div>
          <div class="col">
            <si-form-item [labelWidth]="0" [hideBorder]="true">
              <div class="form-check form-check-inline">
                <label for="level">{{'SHARED.BUILDING'| translate}}</label>
              </div>
            </si-form-item>
          </div>
          <div class="col">
            <si-form-item [labelWidth]="0" [hideBorder]="true">
              <div class="form-check form-check-inline">
                <label for="level">{{ 'SHARED.FLOOR'| translate}}</label>
              </div>
            </si-form-item>
          </div>
        </div>
        <div formArrayName="layouts"
          *ngFor="let layout of layoutsFormArray.controls; let i = index; trackBy:identifyLayouts">
          <div class="row">
            <div class="col-3">
              <si-form-item [hideBorder]="true" [labelWidth]="0">
                <div class="form-control-has-icon" [formGroupName]="i">
                  <input type="checkbox" formControlName="selected" id="layoutName" class="form-check-input">
                  <label class="form-check-label" for="layoutName"
                    translate>{{dwgAssetWithForm.layoutsWithForm.list[i].layoutName}}</label>
                </div>
              </si-form-item>
            </div>
            <div class="col">
              <si-form-item [labelWidth]="0">
                <div [formGroupName]="i" class="form-control-has-icon w-100">
                  <select aria-label="select Building" formControlName="building" class="form-select">
                    <option value="">{{ 'WIZARD.SELECT_BUILDING'| translate}}</option>
                    <ng-container *ngIf="layout.get('selected').value === true">
                      <option *ngFor="let building of projectBuildings; trackBy: identifyLocation"
                        [value]="building.id">
                        {{ building.attributes.label }}
                      </option>
                    </ng-container>
                  </select>
                  <i class="invalid-icon element-cancel-filled"></i>
                </div>
              </si-form-item>
            </div>
            <div class="col">
              <si-form-item [labelWidth]="0">
                <div [formGroupName]="i" class="form-control-has-icon w-100">
                  <select formControlName="floor" aria-label="select Floor" class="form-select">
                    <option value="">Select Floor</option>
                    <ng-container *ngIf="layout.get('building').value">
                      <ng-container *ngFor="let loc of currentFloors; trackBy: identifyLocation">
                        <option *ngIf="layout.get('building').value" [value]="loc.id">
                          {{ loc.attributes.label }}
                        </option>
                      </ng-container>
                    </ng-container>
                  </select>
                  <i class="invalid-icon element-cancel-filled"></i>
                </div>
              </si-form-item>
            </div>
          </div>
        </div>
      </div>
    </si-form-container>
  </form>

  <si-empty-state *ngIf="dwgAssetsError || projectBuildingsError" icon="element-warning" heading="{{'ERROR_MESSAGE.ERROR_MESSAGE4' | translate}}"
    content="{{'WIZARD.ERROR' | translate}}">
  </si-empty-state>

</ng-container>

<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
