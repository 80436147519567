import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { DeviceType } from '../../../../shared/model/dwg-asset.model';
import { DEVICE_TYPES } from '../shared/device-types';
import { DwgAssetWithForms } from '../shared/import-data-with-form.model';

@Component({
  selector: 'app-dwg-blocks',
  templateUrl: './dwg-blocks.component.html',
  styleUrls: ['./dwg-blocks.component.scss']
})
export class DwgBlocksComponent {

  @Input() dwgAssetWithForm?: DwgAssetWithForms;

  deviceTypes: DeviceType[] = DEVICE_TYPES;

  get blocksFormArray() {
    return this.dwgAssetWithForm?.blocksWithForm.form.get('blocks') as FormArray;
  }

  identifyDeviceType(index: string, deviceTypes: DeviceType) {
    return deviceTypes.name;
  }

  identifyBlocks(index: string, block: AbstractControl) {
    return index;
  }
}
