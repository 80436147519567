<form [formGroup]="projectInfo">
  <si-form-container #formContainer
  [form]="projectInfo"
  [enableValidationHelp]="false"
  [errorCodeTranslateKeyMap]="errorCodeTranslateKeyMap">
    <div class="container-fluid" si-form-container-content>
      <si-form-item label="{{'WIZARD.PROJECT_NAME'| translate}}" labelWidth="labelWidth" [required]="true">
        <div class="form-control-has-icon w-100">
          <input type="text" id="projectName" class="form-control" formControlName="projectName" minlength="3">
          <i class="invalid-icon element-cancel-filled"></i>
          <div class="invalid-feedback">
            <span
              *ngFor="let error of formContainer.getValidationErrors('projectName'); trackBy: identifyFormControl"
              [translate]="error.errorCodeTranslationKey" [translateParams]="error.errorParams"></span>
          </div>
        </div>
      </si-form-item>
      <si-form-item label="{{ 'WIZARD.PROJECT_ADDRESS'| translate}}" labelWidth="labelWidth" [labelWrap]="false">
        <input type="text" id="projectAddress" class="w-100 form-control" formControlName="projectAddress" [value]="address">
      </si-form-item>
      <pre></pre>
      <hr>
      <div class="row">
        <div class="col-6">
          <si-form-item label="{{ 'WIZARD.START_DATE'| translate}}" labelWidth="{{labelWidth}}" [required]="true"
            [labelWrap]="false">
            <input type="text" formControlName="startDate" placeholder="{{ 'WIZARD.START_DATE'| translate}}"
              class="form-control" siDatepicker>
            <i class="invalid-icon element-cancel-filled"></i>
            <div class="invalid-feedback">
              <span *ngFor="let error of formContainer.getValidationErrors('startDate'); trackBy: identifyFormControl"
                [translate]="error.errorCodeTranslationKey" [translateParams]="error.errorParams"></span>
            </div>
          </si-form-item>
        </div>
        <div class="col-6">
          <si-form-item label="{{ 'WIZARD.END_DATE'| translate}}" labelWidth="{{labelWidth}}" [required]="true"
            [labelWrap]="false">
            <input type="text" #completionDate formControlName="completionDate"
              placeholder="{{ 'WIZARD.PLANNED_COMPLETION_DATE'| translate}}" class="form-control" siDatepicker>
            <i class="invalid-icon element-cancel-filled"></i>
            <div class="invalid-feedback" [class.d-block]="completionDateErrors?.length > 0"
              *ngIf="completionDate.value">
              <span *ngFor="let error of completionDateErrors; trackBy: identifyFormControl"
                [translate]="error.errorCodeTranslationKey" [translateParams]="error.errorParams"></span>
            </div>
          </si-form-item>
        </div>
      </div>
      <pre></pre>
      <hr>
      <h4>{{ 'WIZARD.INSTALLATION_TEAM'| translate}}</h4>
      <si-form-item [required]="true" labelWidth="labelWidth" hidden>
        <input type="text" id="installerId" class="form-control" placeholder="user Id" formControlName="installerId">
        <i class="invalid-icon element-cancel-filled"></i>
        <div class="invalid-feedback">
          <span *ngFor="let error of formContainer.getValidationErrors('installerId'); trackBy: identifyFormControl"
            [translate]="error.errorCodeTranslationKey" [translateParams]="error.errorParams"></span>
        </div>
      </si-form-item>
      <si-form-item label="{{ 'SHARED.NAME'| translate}}" labelWidth="labelWidth" [required]="true">
        <input type="text" id="installerUser" class="form-control" placeholder="name" formControlName="installerUser">
        <i class="invalid-icon element-cancel-filled"></i>
        <div class="invalid-feedback">
          <span
            *ngFor="let error of formContainer.getValidationErrors('installerUser'); trackBy: identifyFormControl"
            [translate]="error.errorCodeTranslationKey" [translateParams]="error.errorParams"></span>
        </div>
      </si-form-item>
      <div class="d-flex flex-wrap flex-shrink-0 align-items-center justify-content-end bg-base-1 p-0 mb-0">
        <button type="button" class="btn btn-secondary m-2" (click)="clearInstallerUser()">{{ 'SHARED.CLEAR'|
          translate}}</button>
        <button type="button" class="btn btn-primary m-2" (click)="openModal(userListInstaller)"> {{
          'WIZARD.ADD_USER'| translate}}</button>
      </div>

      <pre></pre>
      <hr>
      <h4>{{ 'WIZARD.DESIGN_ENGINEER'| translate}}</h4>
      <si-form-item [required]="true" labelWidth="labelWidth" hidden>
        <input type="text" id="engineerId" class="form-control" placeholder="user Id" formControlName="engineerId">
        <i class="invalid-icon element-cancel-filled"></i>
        <div class="invalid-feedback">
          <span *ngFor="let error of formContainer.getValidationErrors('engineerId'); trackBy: identifyFormControl"
            [translate]="error.errorCodeTranslationKey" [translateParams]="error.errorParams"></span>
        </div>
      </si-form-item>
      <si-form-item label="{{ 'SHARED.NAME'| translate}}" labelWidth="labelWidth" [required]="true">
        <input type="text" id="engineerUser" class="form-control" placeholder="name" formControlName="engineerUser">
        <i class="invalid-icon element-cancel-filled"></i>
        <div class="invalid-feedback">
          <span *ngFor="let error of formContainer.getValidationErrors('engineerUser'); trackBy: identifyFormControl"
            [translate]="error.errorCodeTranslationKey" [translateParams]="error.errorParams"></span>
        </div>
      </si-form-item>
      <div class="d-flex flex-wrap flex-shrink-0 align-items-center justify-content-end bg-base-1 p-0 mb-0">
        <button type="button" class="btn btn-secondary m-2" (click)="clearEngineerUser()">{{ 'SHARED.CLEAR'|
          translate}}</button>
        <button type="button" class="btn btn-primary m-2" (click)="openModal(userListEngineer)"> {{ 'WIZARD.ADD_USER'|
          translate}}</button>
      </div>
      <pre></pre>
      <hr>
      <h4>{{ 'WIZARD.PROJECT_MANAGER'| translate}}</h4>
      <si-form-item [required]="true" labelWidth="labelWidth" hidden>
        <input type="text" id="projectManagerId" class="form-control" placeholder="user Id"
          formControlName="projectManagerId">
        <i class="invalid-icon element-cancel-filled"></i>
        <div class="invalid-feedback">
          <span
            *ngFor="let error of formContainer.getValidationErrors('projectManagerId'); trackBy: identifyFormControl"
            [translate]="error.errorCodeTranslationKey" [translateParams]="error.errorParams"></span>
        </div>
      </si-form-item>
      <si-form-item label="{{ 'SHARED.NAME'| translate}}" labelWidth="labelWidth" [required]="true">
        <input type="text" id="projectManagerUser" class="form-control" placeholder="name"
          formControlName="projectManagerUser">
        <i class="invalid-icon element-cancel-filled"></i>
        <div class="invalid-feedback">
          <span
            *ngFor="let error of formContainer.getValidationErrors('projectManagerUser'); trackBy: identifyFormControl"
            [translate]="error.errorCodeTranslationKey" [translateParams]="error.errorParams"></span>
        </div>
      </si-form-item>
      <div class="d-flex flex-wrap flex-shrink-0 align-items-center justify-content-end bg-base-1 p-0 mb-0">
        <button type="button" class="btn btn-secondary m-2" (click)="clearProjectManagerUser()">{{ 'SHARED.CLEAR'|
          translate}}</button>
        <button type="button" class="btn btn-primary m-2" (click)="openModal(userListProjectManager)"> {{
          'WIZARD.ADD_USER'| translate}}</button>
      </div>
    </div>
  </si-form-container>
</form>
<ng-template #userListInstaller let-modalRef="modalRef">
<div *ngIf="userList$ | async as users">
<app-user-picker [modalRef]="modalRef" [userList]="users" (selectUser)="selectUserInstaller($event); modalRef?.hide()"></app-user-picker>
</div>
</ng-template>
<ng-template #userListEngineer let-modalRef="modalRef" >
<div *ngIf="userList$ | async as users">
<app-user-picker [modalRef]="modalRef" [userList]="users" (selectUser)="selectUserEngineer($event); modalRef?.hide()"></app-user-picker>
</div>
</ng-template>
<ng-template #userListProjectManager let-modalRef="modalRef">
<div *ngIf="userList$ | async as users">
<app-user-picker [modalRef]="modalRef" [userList]="users" (selectUser)="selectUserProjectManager($event); modalRef?.hide()"></app-user-picker>
</div>
</ng-template>
