import { Inject, Injectable } from '@angular/core';
import { Observable, from as observableFrom, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SiemensIdAuthenticationService } from '@building-x/common-ui-ng';
import { config } from '../../assets/app-config';
import { environment } from '../../environments/environment';
export interface AccountInfo {
  title?: string;
  image?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  protected initialized$ = new ReplaySubject<boolean>(1);
  private signSignOnUrl = config.singleSignOnURL;
  private readonly whiteListURLs = ['login', 'logout'];

  constructor(
    private routerObj: Router,
    @Inject(SiemensIdAuthenticationService) private readonly authService: SiemensIdAuthenticationService
  ) { }

  initialize(): void {
    const redirectUrl = document.head.getElementsByTagName('base')[0].href;
    observableFrom(
      this.authService.init(
        environment.clientId,
        environment.issuer,
        redirectUrl,
        redirectUrl,
        environment.audience,
        environment.siemensIdBaseUrl,
        environment.tenant
      )
    ).subscribe(() => {
      this.setURLParams();
      if (this.authService.isAuthenticated()) {
        this.initialized$.next(true);
        if (window.location.href.toString().includes('overview?code=') && !sessionStorage.getItem('entryPointUrl')) {
          this.routerObj.navigate(['/']);
        }
        this.navigateToParam();
      } else if (window.location.href.toString().includes(this.signSignOnUrl)) {
        this.handleSSO();
      } else {
        this.initialized$.next(false);
      }
    });
  }

  get initialized(): Observable<boolean> {
    return this.initialized$.asObservable();
  }


  requireLogin(): Observable<true> {
    return this.initialized.pipe(
      map<boolean, true>(signedIn => {
        if (!signedIn) {
          throw new Error('User not authenticated');
        }
        return signedIn;
      })
    );
  }

  get authToken(): Observable<string> {
    return this.requireLogin().pipe(
      map(() => this.authService.accessToken)
    );
  }

  get accountInfo(): Observable<AccountInfo> {
    return this.requireLogin().pipe(
      map(() => ({
        title: this.authService.identityClaims.name,
        image: this.authService.identityClaims.picture
      }))
    );
  }

  login(): Observable<void> {
    return this.initialized.pipe(
      map(() => this.authService.signIn())
    );
  }

  logout(): Observable<void> {
    return this.initialized.pipe(
      map(() => this.authService.signOut())
    );
  }

  private handleSSO() {
    this.authService.loginSSO().subscribe(res => {
      if (res) {
        // logic to sign in user
        this.initialized.pipe(
          filter(signedIn => !!signedIn)).subscribe();
        this.initialized$.next(true);
        this.navigateToParam();
      }
    });
  }

  private setURLParams() {
    let param = window.location.hash;
    if (param.length > 1 && !this.whiteListURLs.find(ele => param === `#/${ele}`)) {
      if (param.includes(`#${this.signSignOnUrl}`)) {
        param = param.replace(`#${this.signSignOnUrl}`, '');
      } else if (param.startsWith('#/')) {
        param = param.substring(2);
      }
      sessionStorage.setItem('entryPointUrl', param);
    }
  }


  private navigateToParam() {
    const param = sessionStorage.getItem('entryPointUrl');
    if (param) {
      sessionStorage.removeItem('entryPointUrl');
      this.routerObj.navigateByUrl(param);
    } else {
      this.routerObj.navigate(['/']);
    }

  }
}

@Injectable({
  providedIn: 'root'
})
export class MockSessionService extends SessionService {
  override initialize(): void {
    this.initialized$.next(true);
  }
}
