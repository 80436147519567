<div class="card elevation-1">
  <si-file-uploader
    #uploader
    class="card-body"
    [uploadConfig]="uploadConfig"
    [maxFiles]="1"
    [maxConcurrentUploads]="1"
    [accept]="accept"
    (uploadCompleted)="onUploaded($event)"
  ></si-file-uploader>
</div>
