import { Component, Input } from '@angular/core';
import { BuildingFloors } from '../../../../shared/model/building-floors.model';
import { FloorplansList, Layout } from '../../../../shared/model/dwg-asset.model';
import { Floorplans } from '../../../../shared/model/floorplans.model';
import { DwgAssetWithForms } from '../shared/import-data-with-form.model';
import { Location } from '../../../../shared/model/location.model';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-dwg-layouts',
  templateUrl: './dwg-layouts.component.html',
  styleUrls: ['./dwg-layouts.component.scss']
})
export class DwgLayoutsComponent {

  @Input() dwgAssetWithForm?: DwgAssetWithForms;
  @Input() dwgAssetsError?: string;
  @Input() projectBuildings?: BuildingFloors[];
  @Input() projectBuildingsError?: string;
  @Input() currentFloors?: Location[];

  get layoutsFormArray() {
    return this.dwgAssetWithForm?.layoutsWithForm.form.get('layouts') as FormArray;
  }

  identifyLayouts(index: string, layouts: Layout) {
    return layouts?.layoutName;
  }
  identifyFloorplanList(index: string, floorplanList: FloorplansList) {
    return floorplanList.layoutName;
  }
  identifyFloor(index: string, floors: Floorplans) {
    return floors.name;
  }
  identifyLocation(index: string, location: Location) {
    return location?.id;
  }
}
