import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public readonly router: Router,
    public translateService: TranslateService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.toString().includes('temp-csv-uploads-bucket') ||
        request.url.toString().includes('temp-dwg-uploads-bucket')) {
      delete (request.headers as any).Authorization;
      delete (request.headers as any).lazyUpdate;
    }
    return next.handle(request);
  }
}
