import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Me } from '../model/me.model';

interface MeState {
  user?: Me;
}

@Injectable()
export class MeStore extends ComponentStore<MeState> {

  constructor() {
    super({
      user: undefined
    });
  }

  readonly user$ = this.select(state => state.user);

  setUser = this.updater((state, user: Me) => ({
    ...state,
    user
  }));

  public getUser(): Me | undefined {
    return this.get().user;
  }
}
