import { SI_DATATABLE_CONFIG } from '@simpl/element-ng';
import { ColumnMode, SelectionType } from '@siemens/ngx-datatable';

export const projectListTableConfig = {
  ...SI_DATATABLE_CONFIG,
  pageSize: 10,
  headerHeight: 50,
  footerHeight: 0,
  columnMode: ColumnMode.force,
  selectionType: SelectionType.checkbox,
  selectAllRowsOnPage: false,
  externalPaging: false,
  rowHeight: 'auto'
};
