import { Component, Input } from '@angular/core';
import { Layout } from '../../../../shared/model/dwg-asset.model';
import { DwgSvgAssetInfo } from '../../../../shared/model/dwg-svg-asset.model';

@Component({
  selector: 'app-dwg-svg-exports',
  templateUrl: './dwg-svg-exports.component.html',
  styleUrls: ['./dwg-svg-exports.component.scss']
})
export class DwgSvgExportsComponent {
  @Input() public dwgSvgAsset?: DwgSvgAssetInfo[];
  @Input() public dwgSvgAssetError?: string;
  @Input() public selectedLayouts?: Layout[];

  identifyDwgSvgAssetInfo(index: string, asset: DwgSvgAssetInfo) {
    return asset?.id;
  }
}
