import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ProjectApiService } from '../../../shared/services/project-api.service';
import { CreateProjectModel } from './create-project-wizard.component';

interface CreateProjectState {
  createProjectResult?: boolean;
  createProjectError?: string;
}

@Injectable()
export class CreateProjectStore extends ComponentStore<CreateProjectState> {

  constructor(private readonly projectService: ProjectApiService) {
    super({
      createProjectResult: undefined,
      createProjectError: undefined
    });
  }

  readonly createProjectResult$ = this.select(state => state.createProjectResult);
  readonly createProjectError$ = this.select(state => state.createProjectError);

  createProject = this.effect((projectBodyModel$: Observable<CreateProjectModel>) => projectBodyModel$.pipe(
    switchMap(projectModel =>
      this.projectService.createProject(projectModel.project, projectModel.partitionId).pipe(
        tap({
          next: createProjectResult => this.patchState({ createProjectResult }),
          error: createProjectError => this.patchState({ createProjectError })
        }),
        catchError(() => EMPTY)
      )
    ))
  );
}
