import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DeleteConfirmationDialogResult, ModalRef, SiActionDialogService, SiWizardComponent } from '@simpl/element-ng';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PartitionLocationsWithForm } from './shared/data-with-form.model';
import { ProjectInfoUtils } from './shared/project-info-utils';
import { SiAppDataService } from '@building-x/common-ui-ng';
import { LocationService } from '../../../shared/services/location.service';
import { PartitionLocations } from '../../../shared/model/partition-locations.model';
import { Location } from '../../../shared/model/location.model';
import { ProjectUtility } from '../../../shared/utilities/project-utility';
import { MeStore } from '../../../shared/store/me.store';
import { ProjectListStore } from '../project-list.store';
import { Project } from '../../../shared/model/project.model';
import { CreateProjectStore } from './create-project-wizard.store';

export interface CreateProjectModel {
  project: Project;
  partitionId: string;
}
@Component({
  selector: 'app-create-project-wizard',
  templateUrl: './create-project-wizard.component.html',
  styleUrls: ['./create-project-wizard.component.scss'],
  providers: [CreateProjectStore]
})
export class WizardWorkflowComponent implements OnInit {
  @Input() modalRef?: ModalRef;
  @ViewChild('wizard') wizard?: SiWizardComponent;
  partitionLocationsWithForm$?: Observable<PartitionLocationsWithForm>;
  partitionLocationsWithForm?: PartitionLocationsWithForm;
  selectedAddressStream$?: Observable<string | undefined>;
  partitionLocationsStream$?: Observable<Location[] | undefined>;
  createtHasBeenTriggered = false;
  projectInfoForm: FormGroup;
  projectCreationReport$ = this.creatProjectStore.createProjectResult$.pipe(
    tap(res => {
      if (res) {
        this.createSucceeds = res;
      }
    })
  );
  projectCreationError$ = this.creatProjectStore.createProjectError$;
  createSucceeds?: boolean;

  constructor(
    private formBuilder: FormBuilder, private siModal: SiActionDialogService,
    private translateService: TranslateService,
    private readonly location: LocationService,
    private readonly siAppDataService: SiAppDataService,
    private readonly meStore: MeStore,
    private readonly projectListStore: ProjectListStore,
    private readonly creatProjectStore: CreateProjectStore) {
    this.projectInfoForm = this.formBuilder.group({
      projectName: ['', Validators.required],
      projectAddress: [{ value: '', disabled: true }],
      installerUser: [{ value: '', disabled: true }],
      engineerUser: [{ value: '', disabled: true }],
      projectManagerUser: [{ value: '', disabled: true }],
      projectManagerId: ['', Validators.required],
      installerId: ['', Validators.required],
      engineerId: ['', Validators.required],
      startDate: ['', Validators.required],
      completionDate: ['', Validators.required]
    }, { validators: [ProjectInfoUtils.dateValidator()] });
  }

  ngOnInit(): void {
    this.partitionLocationsWithForm$ = this.location.getPartitionsLocations(this.siAppDataService.selectedPartitions)
      .pipe(
        map((list: PartitionLocations[]) => {
          this.partitionLocationsWithForm =
          {
            form: this.formBuilder.group({
              partitionId: ['', [Validators.required]],
              locationId: ['', [Validators.required]]
            }),
            list
          };
          this.partitionLocationsStream$ = this.partitionLocationsWithForm.form.get('partitionId')?.valueChanges.pipe(
            map(partitionId => {
              const partLoc = this.partitionLocationsWithForm?.list.find(p => p.id === partitionId);
              return partLoc?.locations ?? [];
            })
          );
          if (this.partitionLocationsStream$) {
            this.selectedAddressStream$ = this.partitionLocationsWithForm.form.get('locationId')?.valueChanges.pipe(
              switchMap((locationId: string) => {
                const partitionId = this.partitionLocationsWithForm?.form.get('partitionId')?.value;
                return this.location.getLocationAddress(partitionId, locationId).pipe(
                  map(a =>
                    `${a.attributes.street}, ${a.attributes.locality} ,${a.attributes.countryCode},  ${a.attributes.region}`
                  )
                );
              }
              )
            );
          }
          return this.partitionLocationsWithForm;
        })
      );
  }
  cancel() {
    this.siModal.showDeleteConfirmationDialog(
      this.translateService.instant('WIZARD.CANCEL_CONFIRM_MSG'),
      this.translateService.instant('WIZARD.CANCEL_CONFIRM_HEAD'),
      this.translateService.instant('WIZARD.CANCEL_CONFIRM_LEAVE'),
      undefined
    )
      .subscribe(result => {
        switch (result) {
          case DeleteConfirmationDialogResult.Delete:
            this.resetInfo();
            this.modalRef?.hide();
            break;
          case DeleteConfirmationDialogResult.Cancel:
            break;
        }
      });
  }

  resetInfo() {
    this.projectInfoForm?.reset();
  }

  projecCreationTriggered() {
    this.createtHasBeenTriggered = true;
    setTimeout(() => {
      if (this.wizard) {
        this.wizard.next(1);
      }
    });
    const partitionId = this.partitionLocationsWithForm?.form.controls['partitionId'].value;
    const me = this.meStore.getUser();
    if (me?.id && this.partitionLocationsWithForm) {
      const projectRequestBody = ProjectUtility.createProjectRequestBody(this.projectInfoForm, this.partitionLocationsWithForm, me?.id);
      this.creatProjectStore.createProject({ project: projectRequestBody, partitionId });
    }
  }

  onCompletion() {
    this.projectListStore.loadProjectList();
    this.modalRef?.hide();
  }

}
