import { Component, Input } from '@angular/core';
import { PartitionLocationsWithForm } from '../shared/data-with-form.model';
import { PartitionLocations } from '../../../../shared/model/partition-locations.model';
import { Location } from '../../../../shared/model/location.model';
@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss']
})
export class LocationSelectionComponent {

  @Input() partitionLocationsWithForm?: PartitionLocationsWithForm;
  @Input() currentLocations?: Location[];

  identifyPartition(index: string, partition: PartitionLocations) {
    return partition?.id;
  }

  identifyLocation(index: string, location: Location) {
    return location?.id;
  }
}
