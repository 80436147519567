import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UploadInfo } from '../../../shared/model/upload-info.model';
import { Layout } from '../../../shared/model/dwg-asset.model';
import { DwgSvgAssetInfo, LayoutSvg } from '../../../shared/model/dwg-svg-asset.model';
import { AttributesWithForm, DwgAssetWithForms } from './shared/import-data-with-form.model';
import { DwgImportUtils } from './shared/dwg-import-utils';
import { Project } from '../../../shared/model/project.model';
import { Location } from '../../../shared/model/location.model';
import { BuildingFloors } from '../../../shared/model/building-floors.model';
import { FX40i_GROUP_FX40i_GROUP_PARTITION_ID } from '../../../app.constant';
import { DeleteConfirmationDialogResult, ModalRef, SiActionDialogService, SiWizardComponent } from '@simpl/element-ng';
import { ImportDwgStore } from './import-dwg.store';
@Component({
  selector: 'app-import-dwg-wizard',
  templateUrl: './import-dwg-wizard.component.html',
  styleUrls: ['./import-dwg-wizard.component.scss'],
  providers: [ImportDwgStore]
})
export class ImportDwgWizardComponent implements OnInit {
  @Input() modalRef: ModalRef | undefined;
  @Input() project?: Project;

  @ViewChild('wizard') wizard?: SiWizardComponent;

  uploadInfo?: UploadInfo;

  dwgAssetWithForm?: DwgAssetWithForms;

  dwgAssetWithForm$: Observable<DwgAssetWithForms> = this.dwgImportStore.dwgAssets$.pipe(
    map(asset => {
      this.dwgAssetWithForm =
      {
        layoutsWithForm: DwgImportUtils.createLayoutsWithForm(this.formBuilder, asset?.attributes.layouts),
        layersWithForm: DwgImportUtils.createLayersWithForm(this.formBuilder, asset?.attributes.layers),
        blocksWithForm: DwgImportUtils.createBlocksWithForm(this.formBuilder, asset?.attributes.blocks)
      };
      return this.dwgAssetWithForm;
    })
  );

  dwgAssetsError$ = this.dwgImportStore.dwgAssetsError$;

  buildingsFloors$: Observable<BuildingFloors[] | undefined> = this.dwgImportStore.buildingsFloors$;
  buildingsFloorsError$: Observable<string | undefined> = this.dwgImportStore.buildingsFloorsError$;

  currentFloors$?: Observable<Location[]> = this.buildingsFloors$.pipe(
    map(buildings => {
      let floorz: Location[] = [];
      buildings?.forEach(bf => floorz = (bf.floors) ? [...floorz, ...bf.floors] : floorz);
      return floorz;
    })
  );

  dwgSvgAsset$: Observable<DwgSvgAssetInfo[] | undefined> = this.dwgImportStore.dwgSvgAssets$;
  dwgSvgAssetsError$: Observable<string | undefined> = this.dwgImportStore.dwgSvgAssetsError$;

  selectedLayouts: Layout[] = [];

  attributesWithForm?: AttributesWithForm;
  fakePartitionId = FX40i_GROUP_FX40i_GROUP_PARTITION_ID;

  importSucceeds?: boolean;
  dwgImportResult$: Observable<boolean | undefined> = this.dwgImportStore.dwgImportResult$.pipe(tap(res => this.importSucceeds = res));
  dwgImportError$: Observable<string | undefined> = this.dwgImportStore.dwgImportError$;
  importHasBeenTriggered = false;

  constructor(
    private readonly dwgImportStore: ImportDwgStore,
    private formBuilder: FormBuilder, private siModal: SiActionDialogService,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    if (this.project) {
      this.dwgImportStore.setProject(this.project);
    }
  }

  createAttributesWithForm() {
    this.attributesWithForm = DwgImportUtils.createAttributesForm(this.dwgAssetWithForm, this.formBuilder);
  }

  cancel() {
    this.siModal.showDeleteConfirmationDialog(
      this.translateService.instant('WIZARD.CANCEL_CONFIRM_MSG'),
      this.translateService.instant('WIZARD.CANCEL_CONFIRM_HEAD'),
      this.translateService.instant('WIZARD.CANCEL_CONFIRM_LEAVE')
    )
      .subscribe(result => {
        switch (result) {
          case DeleteConfirmationDialogResult.Delete:
            this.modalRef?.hide();
            break;
          case DeleteConfirmationDialogResult.Cancel:
            break;
        }
      });
  }

  onUploadCompleted(uploadInfo: UploadInfo) {
    this.uploadInfo = uploadInfo;
    this.dwgImportStore.setUploadInfo(this.uploadInfo);
    setTimeout(() => {
      if (this.wizard) {
        this.wizard.next(1);
      }
    });
  }

  firstStepAfterUpload() {
    if (this.uploadInfo) {
      this.dwgImportStore.getDwgAsset(this.uploadInfo);
      this.dwgImportStore.loadProjectBuildingsFloors();
    }
  }

  getSvgs() {
    if (this.dwgAssetWithForm?.layoutsWithForm &&
      this.dwgAssetWithForm?.layersWithForm && this.uploadInfo) {

      const latyouSvgBodyReq: LayoutSvg = DwgImportUtils.createSvgBodyReq(this.dwgAssetWithForm?.layoutsWithForm,
        this.dwgAssetWithForm?.layersWithForm, this.uploadInfo);
      this.selectedLayouts = DwgImportUtils.createSelectedLayoutArray(this.dwgAssetWithForm?.layoutsWithForm);
      this.dwgImportStore.getDwgSvgAsset(latyouSvgBodyReq);
    }
  }

  onImportTriggered() {
    this.importHasBeenTriggered = true;
    setTimeout(() => {
      if (this.wizard) {
        this.wizard.next(1);
      }
    });
    this.dwgImportStore.importData();
  }

  onCompletion() {
    this.modalRef?.hide();
  }
}
