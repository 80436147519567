import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dwg-import-result',
  templateUrl: './dwg-import-result.component.html',
  styleUrls: ['./dwg-import-result.component.scss']
})
export class DwgImportResultComponent {
  @Input() dwgImportResult?: boolean;
  @Input() err?: string;
}
