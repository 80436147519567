import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DwgAssetInfo } from '../model/dwg-asset.model';
import { DwgSvgAssetInfo, LayoutSvg } from '../model/dwg-svg-asset.model';
import { UploadInfo } from '../model/upload-info.model';
import { BaseRestService, Response } from './base-rest.service';

@Injectable({
  providedIn: 'root'
})
export class DwgImportService extends BaseRestService<UploadInfo> {

  public override path = `${environment.dwgImporter}`;

  constructor(public override readonly http: HttpClient) {
    super(http);
  }

  getDwgUploadInfo(filename: string, partitionId: string): Observable<UploadInfo> {
    const params = new HttpParams().set('filename', filename);
    this.path = `${environment.dwgImporter}partitions/${partitionId}/dwg-upload`;
    return super.get(params);
  }

  getDwgAsset(partitionId: string, uploadInfo: UploadInfo): Observable<DwgAssetInfo | undefined> {
    if (uploadInfo.filename) {
      const params = new HttpParams().set('filename', uploadInfo.filename);
      const assetsPath = `${environment.dwgExtractor}partitions/${partitionId}/dwg-asset/${uploadInfo.id}`;
      return this.http.get<Response>(assetsPath, { params })
        .pipe(
          map((data: any) => this.unwrapResponse<DwgAssetInfo>(data))
        );
    } else {
      return of(undefined);
    }
  }

  getSvgDwgAsset(partitionId: string, uploadInfo: UploadInfo | undefined,
    latyouSvgBodyReq: LayoutSvg): Observable<DwgSvgAssetInfo[] | undefined> {

    if (uploadInfo?.filename) {
      const params = new HttpParams().set('filename', uploadInfo.filename);
      const assetsPath = `${environment.dwgExtractor}partitions/${partitionId}/dwg-svg-asset/${uploadInfo.id}`;
      return this.http.post<Response>(assetsPath, { data: latyouSvgBodyReq }, { params })
        .pipe(
          map((data: any) => this.unwrapResponse<DwgSvgAssetInfo[]>(data))
        );
    } else {
      return of(undefined);
    }
  }
}
