<form *ngIf="partitionLocationsWithForm else loading" [formGroup]="partitionLocationsWithForm.form"
  class="d-flex h-100">
  <si-form-container #formContainer [form]="partitionLocationsWithForm.form">
    <div si-form-container-content>
      <si-form-item label="Partition" [labelWidth]="140" [required]="true">
        <div class="form-control-has-icon w-100">
          <select aria-label="number" formControlName="partitionId" class="form-select" required>
            <option value="">Select Partition</option>
            <option *ngFor="let part of partitionLocationsWithForm.list; trackBy: identifyPartition" [value]="part.id">
              {{ part.name }}
            </option>
          </select>
          <i class="invalid-icon element-cancel-filled"></i>
        </div>
      </si-form-item>

      <si-form-item label="Campus/Building" [labelWidth]="140" [required]="true">
        <div class="form-control-has-icon w-100">
          <select aria-label="number" formControlName="locationId" class="form-select" required>
            <option value="">Select Campus/Building</option>
            <ng-container *ngIf="partitionLocationsWithForm?.form.get('partitionId').value">
              <option *ngFor="let loc of currentLocations; trackBy: identifyLocation" [value]="loc.id">
                {{ loc.attributes.label }}
                <span *ngIf="loc.type === 'Campus'"> (Campus)</span>
                <span *ngIf="loc.type === 'Building'"> (Building)</span>
              </option>
            </ng-container>
          </select>
          <i class="invalid-icon element-cancel-filled"></i>
        </div>
      </si-form-item>
    </div>
  </si-form-container>
</form>

<ng-template #loading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
