<div class="card elevation-1">
  <div class="card-body" body>
    <ng-container *ngIf="selectedUser">
      {{selectedUser.attributes.identity.emailId + ' '+ ' - '+ selectedUser.attributes.identity.fullName }}
    </ng-container>
    <ng-container *ngIf="!selectedUser">
      {{ 'USER_PICKER.NO_USER_SELECTED' | translate }}
    </ng-container>
  </div>
</div>
